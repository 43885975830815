import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';

class AddUserForm extends Component {
  render(){

	  const { handleSubmit, loading, pricingPlans } = this.props;

	  return (<Form onSubmit={handleSubmit}>
	          <label>First Name</label>
	            <Field name="first_name" component={RenderField} placeholder="First name" as={Form.Input} required/>
	          <label>Last Name</label>
	            <Field name="last_name" component={RenderField} placeholder="Last Name" as={Form.Input} required/>
	          <label>Email</label>
	            <Field name="email" component={RenderField} placeholder="Email" as={Form.Input} required/>
	          <label>Select Pricing Plan</label>
	           <Field name="pricing_plan_id" as={Form.Select} fluid lightweight options={pricingPlans} component={RenderField} placeholder='Select Pricing Plan' width={16} required/>
              <label>Password</label>
	            <Field name="password" type="password" component={RenderField} placeholder="Password" as={Form.Input} required/>
	          <label>Confirm Password</label>
	            <Field name="confirm_password" type="password" component={RenderField} placeholder="Confirm Password" as={Form.Input} required/>
	          
	          <Divider/>
	          <Form.Group>
		          <Button loading={loading} primary type="submit">Submit</Button>
	          </Form.Group>
	        </Form>)
	}
}

AddUserForm = reduxForm({
  form: 'addUserForm',
  enableReinitialize : true
})(AddUserForm)

export default AddUserForm;