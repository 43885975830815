import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';

class ProductServiceForm extends Component {
  render(){

	  const { handleSubmit, loading, products, onSelect } = this.props;

	  return (<Form onSubmit={handleSubmit}>
	          <label>Name</label>
	            <Field name="name" component={RenderField} placeholder="First name" as={Form.Input} required/>
	          <label>Number of Events</label>
	            <Field name="event_total" component={RenderField} placeholder="Number of Events" as={Form.Input} required/>
	          <label>Number of Snaps</label>
	            <Field name="snap_total" component={RenderField} placeholder="Number of Snaps" as={Form.Input} required/>
	          <label>Number Of Bg Removals</label>
	            <Field name="bg_removal_total" component={RenderField} placeholder="Number Of Bg Removals" as={Form.Input} required/>
            <label>Select Product</label>
	           <Field onChange={onSelect} name="product" as={Form.Select} fluid lightweight options={products} component={RenderField} placeholder='Select Product' width={16} required/>
	          <Divider/>
	          <Form.Group>
		          <Button loading={loading} primary type="submit">Submit</Button>
	          </Form.Group>
	        </Form>)
	}
}

ProductServiceForm = reduxForm({
  form: 'productServiceForm',
  enableReinitialize : true
})(ProductServiceForm)

export default ProductServiceForm;