import React, { Component } from "react";
import { Segment, Divider, Icon } from "semantic-ui-react";
import _ from 'lodash';
import AddPageTemplateForm from "../../forms/addPageTemplate form";
import { connect } from "react-redux";
import AddProjectTemplatesForm from "../../forms/addProjectTemplatesForm";
import { bindActionCreators } from "redux";
import { actionCreatorsServicePlan } from "../../../store/ServicePlanFiltered";
import { actionCreatorsIllumeetProjects } from "../../../store/IllumeetProjects";
import { actionCreatorsConfigurations } from "../../../store/Configuration";
import AddCampaignTemplatesForm from "../../forms/addCampaignTemplatesForm";

class AddCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: {}
    }
  }
  componentDidMount() {
    this.props.getServicePlan({ site: 4 })
  }

  componentDidUpdate(prevProps, prevState) {

  }

  goBack = (message, isSuccess) => {
    this.props.history.push({ pathname: '/campaign-templates', state: { message, isSuccess } })
  }

  isError = (isError, errorMessage) => {
    this.setState({
      isError,
      errorMessage
    })
  }

  render() {
    let site = parseInt(localStorage.getItem('site'));
    let isEdit = this.props.location?.state?.row

    return (
      <Segment>

        <div className="row mb-4 mt-2">
          <div className="col">
            <h2>
              <Icon style={{ cursor: 'pointer', }}
                name='chevron left'
                color='black'
                size='small'
                onClick={() => this.goBack()} />

              {isEdit ? 'Edit Campaign' : 'Add Campaign'}
              {this.state.isError &&
                <span classNmae="ml-2" style={{ fontSize: 14, color: 'red', marginLeft: 10 }}>
                  {this.state.isError && this.state.errorMessage}
                </span>}
            </h2>
          </div>
        </div>
        <Divider />
        <AddCampaignTemplatesForm row={this.props.location?.state?.row} goBack={this.goBack} isError={this.isError} />
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    IllumeetProjects: state.IllumeetProjects,
    ServicePlanFiltered: state.ServicePlanFiltered,
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, actionCreatorsIllumeetProjects, actionCreatorsServicePlan), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCampaign)