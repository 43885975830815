import React, { Component } from "react";
import { Segment, Divider, Icon, Button } from "semantic-ui-react";
import _ from 'lodash';
import AddPageTemplateForm from "../../forms/addPageTemplate form";
class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      row: {},
      isError: false,
      errorMessage: ''
    }
  }

  goBack = (message, isSuccess) => {
    this.props.history.push({ pathname: '/page-templates', state: { message, isSuccess } })
  }

  isError = (isError, errorMessage) => {
    this.setState({
      isError,
      errorMessage,
    })
  }

  render() {
    const { loading } = this.state;
    let site = parseInt(localStorage.getItem('site'));
    let isEdit = this.props.location?.state?.row
    return (
      <Segment>
        <div className="row mb-4 mt-2">
          <div className="col">
            <h2>
              <Icon style={{ cursor: 'pointer', }} name='chevron left' color='black' size='small' onClick={() => this.goBack()} />
              {isEdit ? 'Edit Pages' : 'Add Pages'}
              {this.state.errorMessage &&
                <span className="ml-2" style={{ fontSize: 14, color: 'red', margineLeft: 10 }}>
                  {this.state.isError && this.state.errorMessage}
                </span>
              }
            </h2>
          </div>
        </div>

        <Divider />
        <AddPageTemplateForm site={site} row={this.props.location?.state?.row} goBack={this.goBack} isError={this.isError} />
      </Segment>
    );
  }
}
export default AddProject

