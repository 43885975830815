import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid, Icon, Dimmer, Loader} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import modules from "../common/modules";
import { actionCreators } from "../../store/ServicePlan";
import { connect } from "react-redux";
import ServicePlanForm from "../forms/service-plan-form";
import { RenderField, RenderDateField, renderToggleInput } from '../common/renderField';
import toggles from "../common/toggles";
const { REACT_APP_API_URL, REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL }= process.env;

class AddServicePlan extends Component {
  state={
    loading:false,
    alreadyExist: ''
  }
  constructor(props) {
    super(props);
  }

  handleSubmit = async (servicePlan) => {
   this.setState({loading:true, alreadyExist: ''});
   let site= parseInt(localStorage.getItem('site'));
   let data= { plan_name:_.get(servicePlan,'plan_name'), site: site };
   let allModules= Object.keys(modules);
   let allToggles = Object.keys(toggles)
   await Promise.all(allModules.map(module => {
      data[module]= (servicePlan[module])?servicePlan[module]:false;
   }));
   await Promise.all(allToggles.map(toggle => {
      data[toggle]= (servicePlan[toggle])?servicePlan[toggle]:false;
   }));
   data['noOfXPProjects'] = servicePlan['noOfXPProjects'] ? servicePlan['noOfXPProjects'] : 0
   data['noOfXPCampaigns'] = servicePlan['noOfXPCampaigns'] ? servicePlan['noOfXPCampaigns'] : 0
   data['noOfXPSessions'] = servicePlan['noOfXPSessions'] ? servicePlan['noOfXPSessions'] : 0
   data['noOfXPNotifications'] = servicePlan['noOfXPNotifications'] ? servicePlan['noOfXPNotifications'] : 0
   data['noOfXPAttendees'] = servicePlan['noOfXPAttendees'] ? servicePlan['noOfXPAttendees'] : 0
   data['noOfXPCustomFields'] = servicePlan['noOfXPCustomFields'] ? servicePlan['noOfXPCustomFields'] : 0
   data['noOfXPChallenges'] = servicePlan['noOfXPChallenges'] ? servicePlan['noOfXPChallenges'] : 0
   data['noOfXPPages'] = servicePlan['noOfXPPages'] ? servicePlan['noOfXPPages'] : 0
   data['noOfXPContacts'] = servicePlan['noOfXPContacts'] ? servicePlan['noOfXPContacts'] : 0
   data['noOfXPTeamMembers'] = servicePlan['noOfXPTeamMembers'] ? servicePlan['noOfXPTeamMembers'] : 0

   try{
     let site= parseInt(localStorage.getItem('site'));
    let URL;
    if (site == 4){
      URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
    } else {
      URL = REACT_APP_API_URL
    }
     let servicePlanNew= await axios.post(`${URL}/service-plans`,data);
     let status = servicePlanNew.status
     if(status === 200){
      if(servicePlanNew.data.status === 402){
        this.setState({ loading: false, alreadyExist: 'A service plan with the same name already exist' });
      }
     } else {
      servicePlanNew= _.get(servicePlanNew,'data');
      this.props.addService_Plan(servicePlanNew);
      this.setState({loading:false, alreadyExist: ''});
      this.props.history.push(`/service-plans/`);
      
     }
     
   }catch(err){
      this.setState({loading:false, alreadyExist: "Something Went Wrong"});
   }
  };

  loadModules = () => {

    let keys= Object.keys(modules);
    let moduleData = keys.map(k =>{
        return <Grid.Column>
                <Field name={k} component={RenderField} as={Form.Checkbox} label={modules[k]}/>
               </Grid.Column>;
    });
    return <Grid columns='6' style={{minHeight:'5vh'}}>{moduleData}</Grid>;
  }

  loadToggles = () => {
    let keys = Object.keys(toggles)
    let toggleData = keys.map(k => {
      return (
        <Grid.Column>
          <label style={{display: 'block'}}>{toggles[k]}</label>
          <Field name={k} component={renderToggleInput} label={toggles[k]} as={Form.Checkbox}/>
        </Grid.Column>
      )
    })
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{toggleData}</Grid>
  }

  goBack = () => {
    this.props.history.push({ pathname: '/service-plans'})
  }

  render() {
    let site= parseInt(localStorage.getItem('site'));
    const {alreadyExist} = this.state
    return (
      <Segment>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}
        <h1><Icon style={{ cursor: 'pointer', display: 'inline'}} name='chevron left' color='black' size='small' onClick={() => this.goBack()} />Add Service Plan  <span style={{ fontSize: 14, color: 'red', marginLeft: 10 }}>{alreadyExist && 'A service plan with the same name already exist!!'}</span></h1>
        <Divider/>
        <ServicePlanForm site={site} loading={this.state.loading} onSubmit={this.handleSubmit} loadModules={this.loadModules} loadToggles={this.loadToggles}/>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { searchStore, ServicePlan } = state;
    return {
      state: { searchStore, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(AddServicePlan);
