import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import AddUserForm from "../forms/add-user-form";
import { RenderField, RenderDateField } from '../common/renderField';
const { REACT_APP_API_URL }= process.env;

class AddUser extends Component {
  state={
    loading:false,
    pricingPlans:[],
    subscription:{}
  }
  constructor(props) {
    super(props);
  }
  pricingPlans= async () => {
    try{
      let site= parseInt(localStorage.getItem('site'));
      let pricingPlans= await axios.get(`${REACT_APP_API_URL}/pricing-plans?site=${site}`);
      pricingPlans= _.get(pricingPlans,'data');
      this.setState({pricingPlans:pricingPlans});
    }catch(err){

    }
  }

  componentDidMount = async () => {
     await this.pricingPlans();
  }
  

  pricingPlanOptions= () =>{
    const { pricingPlans } = this.state;

    let options= pricingPlans.map(plan => {
        return { text: _.get(plan,'name'), value: _.get(plan,'_id')};
    });

    return options;
  }


  handleSubmit = async (user) => {
   this.setState({loading:true});
   let password= _.get(user,'password');
   let cPassword= _.get(user,'confirm_password');

   if(password !== cPassword){
       this.setState({loading:false});
       throw new SubmissionError({'confirm_password':'Password mismatched'});
   }

   if(!_.get(user,'pricing_plan_id')){
       this.setState({loading:false});
       throw new SubmissionError({'pricing_plan_id':'Please select a pricing plan'});
   }
   let site= parseInt(localStorage.getItem('site'));
    
   let userData= {
        first_name:_.get(user,'first_name'),
        last_name:_.get(user,'last_name'),
        email:_.get(user,'email'),
        site: site,
        role_id:2,
        password: _.get(user,'password')
   };

   try{
     let userNew= await axios.post(`${REACT_APP_API_URL}/users`,userData);
     userNew= _.get(userNew,'data');
     this.props.addUser(userNew);
     let subscriptionData = { 
                              user_id: userNew._id,
                              pricing_plan_id:_.get(user,'pricing_plan_id')
                            };

     console.log("subscriptionData == ",subscriptionData);
     
     let subscription= await axios.post(`${REACT_APP_API_URL}/assign/subscription?site=${site}`,subscriptionData);
     this.setState({loading:false});
     this.props.history.push(`/users`);
   }catch(err){
      this.setState({loading:false});
      throw new SubmissionError({error:err.message});
   }
  };

  render() {
    return (
      <Segment>
        <h1>Add User</h1>
        <Divider/>
        <AddUserForm pricingPlans={this.pricingPlanOptions()} loading={this.state.loading} onSubmit={this.handleSubmit}/>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { searchStore, User } = state;
    return {
      state: { searchStore, User }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(AddUser);
