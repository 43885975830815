import axios from "axios"
const URL = process.env.REACT_APP_API_URL

const SAVE_CAMPAIGN_TEMPLATE_REQUEST = "SAVE_CAMPAIGN_TEMPLATE_REQUEST"
const SAVE_CAMPAIGN_TEMPLATE_SUCCESS = "SAVE_CAMPAIGN_TEMPLATE_SUCCESS"
const SAVE_CAMPAIGN_TEMPLATE_ERROR = "SAVE_CAMPAIGN_TEMPLATE_ERROR"

const GET_ALL_CAMPAIGNS_TEMPLATES_REQUEST = "GET_ALL_CAMPAIGNS_TEMPLATES_REQUEST"
const GET_ALL_CAMPAIGNS_TEMPLATES_SUCCESS = "GET_ALL_CAMPAIGNS_TEMPLATES_SUCCESS"
const GET_ALL_CAMPAIGNS_TEMPLATES_ERROR = "GET_ALL_CAMPAIGNS_TEMPLATES_ERROR"

const DELETE_CAMPAIGN_TEMPLATE_REQUEST = "DELETE_CAMPAIGN_TEMPLATE_REQUEST"
const DELETE_CAMPAIGN_TEMPLATE_SUCCESS = "DELETE_CAMPAIGN_TEMPLATE_SUCCESS"
const DELETE_CAMPAIGN_TEMPLATE_ERROR = "DELETE_CAMPAIGN_TEMPLATE_ERROR"

const UPDATE_CAMPAIGN_TEMPLATE_REQUEST = "UPDATE_CAMPAIGN_TEMPLATE_REQUEST"
const UPDATE_CAMPAIGN_TEMPLATE_SUCCESS = "UPDATE_CAMPAIGN_TEMPLATE_SUCCESS"
const UPDATE_CAMPAIGN_TEMPLATE_ERROR = "UPDATE_CAMPAIGN_TEMPLATE_ERROR"

const initialState = {
    message: null,
    error: false,
    saveCampaignTemplateSuccess: false,
    getAllCampaignsTemplatesSuccess: false,
    campaignTemplateList: [],
    totalResults: 0,
    deleteCampaignTemplateSuccess: false,
    updateCampaignTemplateSuccess: false,
}

const saveCampaignTemplateRequest = (data) => {
    return {
        type: SAVE_CAMPAIGN_TEMPLATE_REQUEST,
        data: data
    }
}

const saveCampaignTemplateSuccess = (message) => {
    return {
        type: SAVE_CAMPAIGN_TEMPLATE_SUCCESS,
        message,
    }
}

const saveCampaignTemplateError = (message) => {
    return {
        type: SAVE_CAMPAIGN_TEMPLATE_ERROR,
        message
    }
}

const getAllCampaignsTemplatesRequest = (data) => {
    return {
        type: GET_ALL_CAMPAIGNS_TEMPLATES_REQUEST,
        data: data
    }
}

const getAllCampaignsTemplatesSuccess = (message, campaignTemplateList, totalResults) => {
    return {
        type: GET_ALL_CAMPAIGNS_TEMPLATES_SUCCESS,
        message,
        campaignTemplateList,
        totalResults
    }
}

const getAllCampaignsTemplatesError = (message) => {
    return {
        type: GET_ALL_CAMPAIGNS_TEMPLATES_ERROR,
        message
    }
}

const deleteCampaignTemplateRequest = (data) => {
    return {
        type: DELETE_CAMPAIGN_TEMPLATE_REQUEST,
        data: data
    }
}

const deleteCampaignTemplateSuccess = (message) => {
    return {
        type: DELETE_CAMPAIGN_TEMPLATE_SUCCESS,
        message,
    }
}

const deleteCampaignTemplateError = (message) => {
    return {
        type: DELETE_CAMPAIGN_TEMPLATE_ERROR,
        message
    }
}

const updateCampaignTemplateRequest = (data) => {
    return {
        type: UPDATE_CAMPAIGN_TEMPLATE_REQUEST,
        data: data
    }
}

const updateCampaignTemplateSuccess = (message) => {
    return {
        type: UPDATE_CAMPAIGN_TEMPLATE_SUCCESS,
        message,
    }
}

const updateCampaignTemplateError = (message) => {
    return {
        type: UPDATE_CAMPAIGN_TEMPLATE_ERROR,
        message
    }
}

export const actionCreatorsCampaignTemplates = {
    saveCampaignTemplate: (data) => async (dispatch) => {
        dispatch(saveCampaignTemplateRequest(data))
        await axios.post(`${URL}/add/campaignTemplates`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(saveCampaignTemplateSuccess(res.data.message))
                    } else {
                        dispatch(saveCampaignTemplateError(res.data.message))
                    }
                } else {
                    dispatch(saveCampaignTemplateError(res.data.message))
                }
            }).catch(err => dispatch(saveCampaignTemplateError(err.message)))
    },

    getAllCampaignTemplates: (data) => async (dispatch) => {
        dispatch(getAllCampaignsTemplatesRequest(data))
        await axios.post(`${URL}/campaignTemplates/listCampaignTemplates/${data.page_no}`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(getAllCampaignsTemplatesSuccess(res.data.message, res.data.campaignTemplateList, res.data.totalResults))
                    } else {
                        dispatch(getAllCampaignsTemplatesError(res.data.message))
                    }
                } else {
                    dispatch(getAllCampaignsTemplatesError(res.data.message))
                }
            }).catch(err => dispatch(getAllCampaignsTemplatesError(err.message)))
    },

    deleteCampaignTemplate: (data) => async (dispatch) => {
        dispatch(deleteCampaignTemplateRequest(data))
        await axios.patch(`${URL}/campaignTemplates/deleteCampaignTemplate/${data.id}`, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(deleteCampaignTemplateSuccess(res.data.message))
                    } else {
                        dispatch(deleteCampaignTemplateError(res.data.message))
                    }
                } else {
                    dispatch(deleteCampaignTemplateError(res.data.message))
                }
            }).catch(err => dispatch(deleteCampaignTemplateError(err.message)))
    },

    updateCampaignTemplate: (data) => async (dispatch) => {
        dispatch(updateCampaignTemplateRequest(data))
        await axios.patch(`${URL}/update/campaignTemplates/${data.id}`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(updateCampaignTemplateSuccess(res.data.message))
                    } else {
                        dispatch(updateCampaignTemplateError(res.data.message))
                    }
                } else {
                    dispatch(updateCampaignTemplateError(res.data.message))
                }
            }).catch(err => dispatch(updateCampaignTemplateError(err.message)))
    },
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CAMPAIGN_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                saveCampaignTemplateSuccess: false,
            }
            break;
        case SAVE_CAMPAIGN_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                saveCampaignTemplateSuccess: true,
            }
            break;

        case SAVE_CAMPAIGN_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                saveCampaignTemplateSuccess: false,
            }
            break;


        case GET_ALL_CAMPAIGNS_TEMPLATES_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                getAllCampaignsTemplatesSuccess: false,
            }
            break;
        case GET_ALL_CAMPAIGNS_TEMPLATES_SUCCESS:
            return {
                ...state,
                message: action.message,
                campaignTemplateList: action.campaignTemplateList,
                totalResults: action.totalResults,
                error: false,
                getAllCampaignsTemplatesSuccess: true,
            }
            break;

        case GET_ALL_CAMPAIGNS_TEMPLATES_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                getAllCampaignsTemplatesSuccess: false,
            }
            break;

        case DELETE_CAMPAIGN_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                deleteCampaignTemplateSuccess: false,
            }
            break;
        case DELETE_CAMPAIGN_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                deleteCampaignTemplateSuccess: true,
            }
            break;

        case DELETE_CAMPAIGN_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                deleteCampaignTemplateSuccess: false,
            }
            break;

        case UPDATE_CAMPAIGN_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                updateCampaignTemplateSuccess: false,
            }
            break;
        case UPDATE_CAMPAIGN_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                updateCampaignTemplateSuccess: true,
            }
            break;

        case UPDATE_CAMPAIGN_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                updateCampaignTemplateSuccess: false,
            }
            break;


        default: return state
            break;
    }

}
