const GET_ALL_SERVICE_PLANS_ACTION = "GET_ALL_SERVICE_PLANS";
const DELETE_SERVICE_PLAN_ACTION = "DELETE_SERVICE_PLAN";
const UPDATE_SERVICE_PLAN_ACTION = "UPDATE_SERVICE_PLAN";
const ADD_SERVICE_PLAN_ACTION = "ADD_SERVICE_PLAN";

const initialState = {
  servicePlans: []
};

export const actionCreators = {
  getServicePlans: (servicePlans) => async (dispatch, getState) => {
    dispatch({ type: GET_ALL_SERVICE_PLANS_ACTION, servicePlans: servicePlans });
  },
  removeServicePlan: id => async (dispatch, getState) => {
    dispatch({
      type: DELETE_SERVICE_PLAN_ACTION,
      servicePlans: getState().ServicePlan.servicePlans.filter(m => m.id !== id)
    });
  },
  editService_Plan: SERVICE_PLAN => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SERVICE_PLAN_ACTION,
      servicePlans: getState().ServicePlan.servicePlans.map(
        m => (m.id === SERVICE_PLAN.id ? SERVICE_PLAN : m)
      )
    });
  },
  addService_Plan: SERVICE_PLAN => async (dispatch, getState) => {
    dispatch({
      type: ADD_SERVICE_PLAN_ACTION,
      servicePlan: SERVICE_PLAN
    });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === GET_ALL_SERVICE_PLANS_ACTION) {
    return { ...state, servicePlans: action.servicePlans };
  }
  if (action.type === UPDATE_SERVICE_PLAN_ACTION) {
    return { ...state, servicePlans: action.servicePlans };
  }
  if (action.type === ADD_SERVICE_PLAN_ACTION) {
    return { ...state, servicePlans: [...state.servicePlans, action.servicePlan] };
  }

  return state;
};
