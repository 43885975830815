import React, { Component } from "react";
import {
    Button,
    Header,
    Icon,
    Message,
    Segment,
    Table,
    Divider,
    Confirm,
    Grid,
    Dropdown,
    Modal,
    Loader,
    Dimmer,
    Label,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { actionCreatorsConfigurations } from "../../store/Configuration";

const { REACT_APP_API_URL } = process.env;
const customStyles = {
    rows: {
      style: {
        minHeight: '68px', // override the row height
        border: '0 !important',
      }
    },
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        paddingLeft: '16px', // override the cell padding for head cells
        paddingRight: '16px',
      },
    },
    cells: {
      style: {
        paddingLeft: '18px', // override the cell padding for data cells
        paddingRight: '18px',
      },
    },
  };

class ChangeSystemAccount extends Component {

    constructor(props) {
        super(props);
        console.log("this.props", this.props);
        this.state = {
            systemAccount: {},
            selectedSystemAccountId: '',
            selectedSystemAccountEmail: '',
            errors: {},
            openConfirmationModal: false,
            loading: false,
        }
    }

    componentDidMount = async () => {
        this.props.getAllSystemAccounts()
        this.props.getSystemAccount()
        this.setState({
            loading: true
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.Configuration?.getAllSystemAccountsSuccess && !prevProps.Configuration?.getAllSystemAccountsSuccess) {
            let allSystemAccounts = this.props.Configuration?.allSystemAccounts
            allSystemAccounts = allSystemAccounts.map(obj => ({ text: obj.email, value: { email: obj.email, _id: obj._id }, key: obj._id }))
            this.setState({
                allSystemAccounts
            })
            this.state.loading && this.handleLoading()
        }
        if (this.props.Configuration?.getSystemAccountSuccess && !prevProps.Configuration?.getSystemAccountSuccess) {
            let email = this.props.Configuration?.systemAccountEmail
            let _id = this.props.Configuration?.systemAccountId
            let systemAccount = {
                _id,
                email,
            }
            this.setState({
                systemAccount: systemAccount,
                selectedSystemAccountId: _id,
                selectedSystemAccountEmail: email,
            })
            this.state.loading && this.handleLoading()
        }
        if (this.props.Configuration?.savesystemAccountSuccess && !prevProps.Configuration?.savesystemAccountSuccess) {
            this.props.getSystemAccount()
            this.state.openConfirmationModal && this.closeConfirmationModal()
            this.state.loading && this.handleLoading()
        }

        if (this.props.Configuration?.error && !prevProps.Configuration?.error) {
            this.state.loading && this.handleLoading()
        }
    }

    onChange = (e, data) => {
        this.setState({
            selectedSystemAccountEmail: data.value.email,
            selectedSystemAccountId: data.value._id,
            systemAccount: {
                email: data.value.email,
                _id: data.value._id
            }
        })
    }

    isValid = () => {
        let isValid = true
        let error = {}
        const { selectedSystemAccountId } = this.state

        if (selectedSystemAccountId == "" || selectedSystemAccountId == undefined) {
            error["systemAccount"] = "Please select an email"
            isValid = false
        }
        this.setState({
            errors: error
        })
        return isValid
    }

    saveSystemAccount = () => {
        const { selectedSystemAccountId, selectedSystemAccountEmail } = this.state
        if (this.isValid()) {
            const requestBody = {
                systemAccountId: selectedSystemAccountId,
                email: selectedSystemAccountEmail
            }
            this.props.saveSystemAccounts(requestBody)
            this.setState({ loading: true })
        }
    }

    openConfirmationModal = () => {
        this.setState({
            openConfirmationModal: true
        })
    }

    closeConfirmationModal = () => {
        this.setState({
            openConfirmationModal: false
        })
    }

    handleLoading = () => {
        let loading = this.state.loading;
        setTimeout(() => {
            this.setState({
                loading: !loading,
            });
        }, 200);
    };

    render() {
        const { allSystemAccounts, systemAccount, errors } = this.state
        return (
            <Segment>
                {this.state.loading ? (
                    <Dimmer active inline="centered" inverted>
                        <Loader />
                    </Dimmer>
                ) : null}
                <div>

                    <div>
                        <div className="row mb-4 mt-2">
                            <div className="col">
                                <h2>
                                    Configuration
                                </h2>
                            </div>
                        </div>
                        <label>Select System Account</label>
                        <Dropdown search onChange={(e, data) => this.onChange(e, data)} style={{ width: '100%' }} defaultValue={systemAccount} value={systemAccount} direction='downward' placeholder='Select' fluid selection options={allSystemAccounts} />
                        <p className="required small-subtitle" style={{ color: "#ff0000" }}> {errors.systemAccount}</p>
                        <hr style={{ width: "100%" }}></hr>
                        <Button primary onClick={this.openConfirmationModal}>Save</Button>
                    </div>
                    <Modal
                        onClose={this.closeConfirmationModal}
                        onOpen={this.openConfirmationModal}
                        open={this.state.openConfirmationModal}
                    >
                        <Modal.Header>Confirmation</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <p>
                                    Are you sure you want to change the system account? You may lose all the templates and mapping.
                                </p>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="text" onClick={this.closeConfirmationModal}>
                                Cancel
                            </Button>
                            <Button primary type="text" onClick={this.saveSystemAccount}>
                                Yes
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Configuration: state.Configuration
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actionCreatorsConfigurations, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSystemAccount);
