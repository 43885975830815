import axios from "axios"
const URL = process.env.REACT_APP_API_URL
const Illumeet_URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL

const GET_ALL_SYSTEM_ACCOUNTS_REQUEST = "GET_ALL_SYSTEM_ACCOUNTS_REQUEST"
const GET_ALL_SYSTEM_ACCOUNTS_SUCCESS = "GET_ALL_SYSTEM_ACCOUNTS_SUCCESS"
const GET_ALL_SYSTEM_ACCOUNTS_ERROR = "GET_ALL_SYSTEM_ACCOUNTS_ERROR"

const SAVE_SYSTEM_ACCOUNT_REQUEST = "SAVE_SYSTEM_ACCOUNT_REQUEST"
const SAVE_SYSTEM_ACCOUNT_SUCCESS = "SAVE_SYSTEM_ACCOUNT_SUCCESS"
const SAVE_SYSTEM_ACCOUNT_ERROR = "SAVE_SYSTEM_ACCOUNT_ERROR"

const GET_SYSTEM_ACCOUNT_REQUEST = "GET_SYSTEM_ACCOUNT_REQUEST"
const GET_SYSTEM_ACCOUNT_SUCCESS = "GET_SYSTEM_ACCOUNT_SUCCESS"
const GET_SYSTEM_ACCOUNT_ERROR = "GET_SYSTEM_ACCOUNT_ERROR"

const initialState = {
    allSystemAccounts: [],
    message: null,
    error: false,
    getAllSystemAccountsSuccess: false,
    systemAccountId: '',
    systemAccountEmail: '',
    savesystemAccountSuccess: false,
    getSystemAccountSuccess: false,
}

//Action creator for getting the list of all the emails

const getAllSystemAccountsRequest = (data) => {
    return {
        type: GET_ALL_SYSTEM_ACCOUNTS_REQUEST,
        data,
    }
}

const getAllSystemAccountsSuccess = (data, message) => {
    return {
        type: GET_ALL_SYSTEM_ACCOUNTS_SUCCESS,
        data,
        message,
    }
}

const getAllSystemAccountsError = (message) => {
    return {
        type: GET_ALL_SYSTEM_ACCOUNTS_ERROR,
        message,
    }
}

// Action creator for saving the system account

const saveSystemAccountsRequest = (data) => {
    return {
        type: SAVE_SYSTEM_ACCOUNT_REQUEST,
        data,
    }
}

const saveSystemAccountsSuccess = (message) => {
    return {
        type: SAVE_SYSTEM_ACCOUNT_SUCCESS,
        message,
    }
}

const saveSystemAccountsError = (message) => {
    return {
        type: SAVE_SYSTEM_ACCOUNT_ERROR,
        message,
    }
}

// Action creator for getting the system account

const getSystemAccountsRequest = () => {
    return {
        type: GET_SYSTEM_ACCOUNT_REQUEST,
    }
}

const getSystemAccountSuccess = (systemAccountId, systemAccountEmail, message) => {
    return {
        type: GET_SYSTEM_ACCOUNT_SUCCESS,
        systemAccountId,
        systemAccountEmail,
        message,
    }
}

const getSystemAccountsError = (message) => {
    return {
        type: GET_SYSTEM_ACCOUNT_ERROR,
        message
    }
}

// Thunk Action creator

export const actionCreatorsConfigurations = {
    // list of all emails
    getAllSystemAccounts: () => async (dispatch, getState) => {
        dispatch(getAllSystemAccountsRequest())
        await axios.get(`${Illumeet_URL}/api/listAllSystemAccounts`).then(res => { // Have to change the URL
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(getAllSystemAccountsSuccess(res.data.data, res.data.message))
                } else {
                    dispatch(getAllSystemAccountsError(res.data.error))
                }
            } else {
                dispatch(getAllSystemAccountsError(res.data.error))
            }
        }).catch(err => dispatch(getAllSystemAccountsError(err.message)))
    },

    // saving system account
    saveSystemAccounts: (data) => async (dispatch, getState) => {
        dispatch(saveSystemAccountsRequest(data))
        await axios.post(`${URL}/configurations/saveSystemAccount`, data,
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(saveSystemAccountsSuccess(res.data.message))
                } else {
                    dispatch(saveSystemAccountsError(res.data.error))
                }
            } else {
                dispatch(saveSystemAccountsError(res.data.error))
            }
        }).catch(err => dispatch(saveSystemAccountsError(err.error)))
    },

    // fetching the system account
    getSystemAccount: () => async(dispatch, getState) => {
        dispatch(getSystemAccountsRequest())
        await axios.get(`${URL}/configurations/getSystemAccount`)
            .then(res => {
                if (res.status === 200) {
                    if (res.status === 200) {
                        dispatch(getSystemAccountSuccess(res.data.systemAccountId, res.data.systemAccountEmail, res.data.message))
                    } else {
                        dispatch(getSystemAccountsError(res.data.error))
                    }
                } else {
                    dispatch(getSystemAccountsError(res.data.error))
                }
            }).catch(err => dispatch(getSystemAccountsError(err.message)))
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        // Reducer getAllSystemAccounts
        case GET_ALL_SYSTEM_ACCOUNTS_REQUEST:
            return {
                ...state,
                getAllSystemAccountsSuccess: false,
                message: null,
                error: false,
            }
            break;

        case GET_ALL_SYSTEM_ACCOUNTS_SUCCESS:
            return {
                ...state,
                allSystemAccounts: action.data,
                message: action.message,
                error: false,
                getAllSystemAccountsSuccess: true
            }
            break;
        case GET_ALL_SYSTEM_ACCOUNTS_ERROR:
            return {
                ...state,
                allSystemAccounts: [],
                message: action.message,
                error: true,
                getAllSystemAccountsSuccess: false,
            }
            break;

        // Reducer saveSystemAccounts
        case SAVE_SYSTEM_ACCOUNT_REQUEST:
            return {
                ...state,
                savesystemAccountSuccess: false,
                message: null,
                error: false
            }
            break;

        case SAVE_SYSTEM_ACCOUNT_SUCCESS:
            return {
                ...state,
                savesystemAccountSuccess: true,
                message: action.message,
                error: false
            }
            break;

        case SAVE_SYSTEM_ACCOUNT_ERROR:
            return {
                ...state,
                savesystemAccountSuccess: false,
                message: action.message,
                error: true
            }
            break;

        // Reducer saveSystemAccounts
        case GET_SYSTEM_ACCOUNT_REQUEST:
            return {
                ...state,
                getSystemAccountSuccess: false,
                systemAccountId: '',
                systemAccountEmail: '',
                message: null,
                error: false,
            }
            break;

        case GET_SYSTEM_ACCOUNT_SUCCESS:
            return {
                ...state,
                getSystemAccountSuccess: true,
                message: action.message,
                systemAccountId: action.systemAccountId,
                systemAccountEmail: action.systemAccountEmail,
                error: false,
            }
            break;

        case GET_SYSTEM_ACCOUNT_ERROR:
            return {
                ...state,
                getSystemAccountSuccess: false,
                message: action.message,
                error: true,
            }
            break;

        default: return state
            break;
    }
}