import React, { Component } from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import LoginForm from '../forms/loginForm';
import axios from 'axios';
import { Field, SubmissionError } from 'redux-form';
import { Redirect } from 'react-router';
const { REACT_APP_API_URL }= process.env;

class Login extends Component {
  state={
    loading:false,
    errorMessage:false
  }
  handleSubmit = async (loginData) => {
    this.setState({loading:true});

    try{
      let user= await axios.post(`${REACT_APP_API_URL}/login`,loginData);
      this.setState({loading:false});
      localStorage.setItem('authorization',user.jwt);
      localStorage.setItem('site','1');
      window.location.href= '/';
    }catch(err){
      this.setState({loading:false,errorMessage:'Either email or password wrong'});
    }
  }
  render(){
  	    const { loading, errorMessage }= this.state;

	  	return (<Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
			    <Grid.Column style={{ maxWidth: 450 }}>
			      <Header as='h2' color='teal' textAlign='center'>
			        Login to your account
			      </Header>
			      <LoginForm errorMessage={errorMessage} loading={loading} onSubmit={this.handleSubmit} />
			    </Grid.Column>
			  </Grid>)
  	}
}

export default Login;