import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form,
  Segment,
  Message
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';

class LoginForm extends Component {
  render(){

	  const { handleSubmit, loading, errorMessage } = this.props;

	  return (<Form onSubmit={handleSubmit} size='large'>
	  	      <Segment stacked>
	  	      {errorMessage && <Message negative><p>{errorMessage}</p></Message>}
	          <Field name="email" component={RenderField} placeholder="E-mail" as={Form.Input} required/>
	          <Field name="password" type="password" component={RenderField} placeholder="password" as={Form.Input} required/>
              <Button loading={loading} color='teal' type='submit' fluid size='large'>Login</Button>
	          </Segment>
	        </Form>)
	}
}

LoginForm = reduxForm({
  form: 'loginForm',
  enableReinitialize : true
})(LoginForm)

export default LoginForm;