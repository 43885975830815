import React, { Component } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  Divider,
  Confirm,
  Grid,
  Input,
  Modal
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/ServicePlan";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import modules from "../common/modules";
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import DeleteIcon from "../../components/assets/icons/delete.svg";

const { REACT_APP_API_URL, REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL } = process.env;
const customStyles = {
  rows: {  
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class ServicePlan extends Component {
  state = {
    open: false,
    offset: 0,
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    openDeleteConfirmationModal: false,
    deleteId: '',
  }
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  servicePlans = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let URL; 
      if(site == 4){
        URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      let servicePlans = await axios.get(`${URL}/service-plans?site=${site}`);
      servicePlans = _.get(servicePlans, 'data');
      this.props.getServicePlans(servicePlans);
    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.servicePlans();
  }

  activeModules = (plan) => {
    let Modules = Object.keys(modules);
    let activeModules = 0;

    Modules.map(module => {
      activeModules += (plan[module]) ? 1 : 0;
    });

    return activeModules;

  }

  handleClick(event) {
    const offset = event.selected * this.state.dataPerPage
    this.setState({
      currentPage: Number(event.selected + 1),
      offset: offset
    });
  }

  getTableData = () => {
    return this.props.state.ServicePlan.servicePlans.map(plan => <Table.Row>
      <Table.Cell>{plan.plan_name}</Table.Cell>
      <Table.Cell>{this.activeModules(plan)}</Table.Cell>
      <Table.Cell>
        <Button.Group>
          {/*<Button onClick= {(planId) => {this.deletePlan(plan.id)}}>Delete</Button>
            <Button.Or />*/}
          <Link to={{ pathname: `/service-plans/${plan.id}/edit` }}><Button positive>Edit</Button></Link>
        </Button.Group>
      </Table.Cell>
    </Table.Row>);
  }

  open = (planId) => {
    this.setState({ planToDelete: planId, open: true });
  }

  deletePlan = async (servicePlanId) => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let URL;
      if(site == 4){
        URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      await axios.delete(`${URL}/service-plans/${servicePlanId}`);
      this.props.removeServicePlan(servicePlanId);
    } catch (err) {
      console.log(err);
    }
  }
  loadData = () => {
    if (!this.props.state.ServicePlan.servicePlans) {
      return <Table.Row><Table.Cell colSpan={5}>Loading plans ...</Table.Cell></Table.Row>;
    } else {
      if (this.props.state.ServicePlan.servicePlans.length > 0) {
        return this.getTableData();
      } else {
        return <Table.Row><Table.Cell colSpan={5}>No plans found</Table.Cell></Table.Row>;
      }
    }
  }


  openDeleteConfirmationModal = (id) => {
    this.setState({
      openDeleteConfirmationModal: true,
      deleteId: id,
    })
  }
  deleteServicePlan = async () => {
    let site = parseInt(localStorage.getItem('site'));
    let URL;
      if(site == 4){
        URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      await axios.patch(`${URL}/service-plans/delete/${this.state.deleteId}`, { isDeleted: true }).then(res => {
      this.setState({
        openDeleteConfirmationModal: false
      })
      this.servicePlans();

    }).catch(err => { console.log('err', err) })
  }

  closeDeleteConfirmationModal = () => {
    this.setState({
      openDeleteConfirmationModal: false,
      deleteId: ""
    })
  }

  render() {
    const { todos, currentPage, dataPerPage, searchTerm, open } = this.state;
    // const { open }= this.state;
    // =================== Logic for displaying todos=================================
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentTodos = this.props.state.ServicePlan.servicePlans.slice(indexOfFirstData, indexOfLastData);


    // ===================SEARCH BAR======================================
    // var data = [...currentTodos];  //TOTAL DATA
    var data = [...currentTodos].filter((value) => {
      if (searchTerm == "") {
        return value
      }
      else if (value.plan_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })

    var columns = [
      {
        name: 'Plan Name',
        selector: 'plan_name',
        sortable: true,
        center: true
      },
      {
        name: 'Active Modules',
        cell: row => <span>{this.activeModules(row)}</span>,
        sortable: true,
        center: true

      },

      {
        name: 'Action',
        cell: row => 
        <div>
          <Link to={{ pathname: `/service-plans/${row.id}/edit` }}><Button size='mini' primary>Edit</Button></Link>
          <Button size='mini' color='red' style={{ paddingTop: '8px', paddingBottom: '6px', }} onClick={() => this.openDeleteConfirmationModal(row.id)}><img alt="Delete" height={'15'} src={DeleteIcon} /></Button>
        </div>,
        center: true
      },

    ];





    if (!this.props.state.ServicePlan.servicePlans) {
      return <Message>Service Plans Loading ...</Message>;
    }
    return (
      <div>
        <Segment>
          <div className="row mb-4 mt-2">
            <div className="col">
              <h2>
                Service Plans
              </h2>
            </div>
            <div className="col">
              <div className="d-flex w-100 justify-content-end">
                <div>
                  <Input type="text" size='mini' icon='search' placeholder="search.... " onChange={event => { this.setState({ searchTerm: event.target.value }) }} />
                </div>
                <div className="ml-3">
                  <Link to='/service-plans/add'><Button size='mini' primary>Add</Button></Link>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
          />



          {/* <Grid style={{minHeight:0}}>
        <Grid.Column width={14}>
          <h1>Service Plans</h1>
        </Grid.Column>
        <Grid.Column width={2} textAlign='right'>
          <Link to='/service-plans/add'><Button primary>Add New</Button></Link>
        </Grid.Column>
        </Grid>
        <Divider/>
        <div style={{overflow:'auto'}}>
        <Table textAlign='center' celled padded striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Service Plan Name</Table.HeaderCell>
              <Table.HeaderCell>Active Modules</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{this.loadData()}</Table.Body>

          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={17} />
            </Table.Row>
          </Table.Footer>
        </Table>
        </div> */}
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(this.props.state.ServicePlan.servicePlans.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />

        <Modal
          onClose={this.closeDeleteConfirmationModal}
          onOpen={this.openDeleteConfirmationModal}
          open={this.state.openDeleteConfirmationModal}
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                Are you sure you want to delete?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button type="text" onClick={this.closeDeleteConfirmationModal}>
              Cancel
            </Button>
            <Button primary type="text" onClick={this.deleteServicePlan}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        
      </div>
    );
  }
}


export default connect(
  state => {
    const { searchStore, ServicePlan } = state;
    return {
      state: { searchStore, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(ServicePlan);
