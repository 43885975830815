let site = parseInt(localStorage.getItem('site'));
let toggles = {}
if (site == 4) {
    toggles = {
        customJSXP: "Custom JS",
        customCSSXP: "Custom CSS",
        hybridProjectsXP: "Hybrid Projects",
        virtualProjectsXP: "Virtual Projects",
    }
}

export default toggles