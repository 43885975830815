import React, { Component } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  Divider,
  Confirm,
  Grid
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/ServicePlan";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import modules from "../common/modules";
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
const { REACT_APP_API_URL } = process.env;
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class ProductService extends Component {
  state = {
    open: false,
    offset: 0,
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    productServices:[]
  }
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  productServices = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let productServices = await axios.get(`${REACT_APP_API_URL}/productService?site=${site}`);
      this.setState({productServices:productServices.data, currentPage:1, offset:0});
    } catch (err) {

    }
  }

  componentDidMount = async () => {
    await this.productServices();
  }

  handleClick(event) {
    const offset = event.selected * this.state.dataPerPage
    this.setState({
      currentPage: Number(event.selected + 1),
      offset: offset
    });
  }

  

  render() {
    const { todos, currentPage, dataPerPage, searchTerm, open } = this.state;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentTodos = this.state.productServices.slice(indexOfFirstData, indexOfLastData);

    var data = [...currentTodos].filter((value) => {
      if (searchTerm == "") {
        return value
      }
      else if (value.plan_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


    var columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        center:true
      },
      {
        name: 'Product',
        selector: 'product.name',
        sortable: true,
        center:true
      },
      {
        name: 'Action',
        cell: row => <Button.Group>
          <Link to={{ pathname: `/credits-configuration/${row.id}/edit` }}><Button positive>Edit</Button></Link>
        </Button.Group>,
        center:true
      },

    ];





    if (!this.state.productServices) {
      return <Message>Product Services Loading ...</Message>;
    }
    return (
      <div>
        <Segment>
        <Grid style={{minHeight:0}}>
        <Grid.Column width={10}>
          <h1>Credits Configuration</h1>
        </Grid.Column>
        <Grid.Column width={4} >
        <input type="text" placeholder="search.... "  class="search" onChange={event => { this.setState({ searchTerm: event.target.value }) }} />
        </Grid.Column>
        <Grid.Column width={2} textAlign='right'>
          <Link to='/credits-configuration/add'><Button primary>Add New</Button></Link>
        </Grid.Column>
        </Grid>
          <DataTable
            columns={columns}
            data={data} 
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
            />
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(this.state.productServices.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />
      </div>
    );
  }
}


export default ProductService;
