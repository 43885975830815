import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactPaginate from 'react-paginate';
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Segment,
  Table,
  Grid,
  Divider,
  Confirm,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Loader from '../common/LoaderComponent';
import DataTable from 'react-data-table-component';
const { REACT_APP_API_URL,
  REACT_APP_CLIENT_PANEL_ILLUSNAP_URL,
  REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL,
  REACT_APP_CLIENT_PANEL_ILLUFLIP_URL,
  REACT_APP_CLIENT_PANEL_ILLUFLIP_API_URL,
  REACT_APP_CLIENT_PANEL_ILLUTAG_URL,
  REACT_APP_CLIENT_PANEL_ILLUTAG_API_URL,
  REACT_APP_CLIENT_PANEL_ILLUMEET_XP_URL,
  REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
} = process.env;
import { CSVLink, CSVDownload } from "react-csv";
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};


class User extends Component {
  state = {
    offset: 0,
    loading: {},
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    open: false
  }
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  Users = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let users = await axios.get(`${REACT_APP_API_URL}/users?site=${site}`);
      users = _.get(users, 'data');
      this.props.getUsers(users);
    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.Users();
  }
  autoLogin = async (user) => {
    let copyStates = Object.assign({}, this.state);
    copyStates.loading[user.id] = true;
    this.setState(copyStates);
    let data = { user_id: user.id };
    if (localStorage.getItem('site') == 1) {
      try {
        let response = await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/admin/initiate/autoLogin`, data);
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
        let hash = _.get(response, 'data.hash');
        window.open(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_URL}/auto-login/${hash}`, "_blank");
      } catch (err) {
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
      }
    }
    else if (localStorage.getItem('site') == 2) {
      try {



        var data1 = JSON.stringify({
          "user_id": user.id
        });

        var config = {
          method: 'post',
          url: `${REACT_APP_CLIENT_PANEL_ILLUTAG_API_URL}/initiate/autoLogin`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data1
        };

        axios(config)
          .then(response => {


            copyStates.loading[user.id] = false;
            this.setState(copyStates);
            window.open(`${REACT_APP_CLIENT_PANEL_ILLUTAG_URL}/autoLoginToken/${response.data.data.hash}`, "_blank");
          })
          .catch(err => {
            copyStates.loading[user.id] = false;
            this.setState(copyStates);
          });

        // let response= await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/admin/initiate/autoLogin`,data);
        // copyStates.loading[user.id]= false;
        // this.setState(copyStates);
        // let hash= _.get(response,'data.hash');
        // window.open(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_URL}/auto-login/${hash}`, "_blank");
      } catch (err) {
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
      }
    }
    else if (localStorage.getItem('site') == 3) {
      try {
        let response = await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUFLIP_API_URL}/users/initiate/autoLogin`, data);
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
        let hash = _.get(response, 'data.hash');
        window.open(`${REACT_APP_CLIENT_PANEL_ILLUFLIP_URL}/auto-login/${hash}`, "_blank");
      } catch (err) {
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
      }
    } else if (localStorage.getItem('site') == 4) {
      try {
        //REACT_APP_CLIENT_PANEL_ILLUMEET_XP_URL=http://localhost:3000 
        //REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL=http://localhost:5000 
        let response = await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL}/admin/initiate/autoLogin`, data);
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
        let hash = _.get(response, 'data.hash');
        window.open(`${REACT_APP_CLIENT_PANEL_ILLUMEET_XP_URL}/auto-login/${hash}`, "_blank");
      } catch (err) {
        copyStates.loading[user.id] = false;
        this.setState(copyStates);
      }
    }

  }

  handleClick(event) {
    console.log("event", event.selected)
    const offset = event.selected * this.state.perPage
    this.setState({
      currentPage: Number(event.selected + 1),
      offset: offset
    });
  }

  getTableData = () => {
    return this.props.state.User.users.map(user => <Table.Row>
      <Table.Cell> {user.first_name}</Table.Cell>
      <Table.Cell>{user.last_name}</Table.Cell>
      <Table.Cell>{user.email}</Table.Cell>
      <Table.Cell width={4}>
        <Button.Group>
          <Button loading={this.state.loading[user.id]} onClick={(u) => { this.autoLogin(user) }}>Client Login</Button>
          <Button.Or />
          {user.subscription ?
            (<Link to={{ pathname: `/users/${user.id}/subscription` }}><Button positive>Subscription detail</Button></Link>) :
            (<a><Button disabled positive>Subscription detail</Button></a>)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>);
  };

  loadData = () => {
    if (!this.props.state.User.users) {
      return <Table.Row><Table.Cell colSpan={5}>Loading users ...</Table.Cell></Table.Row>;
    } else {
      if (this.props.state.User.users.length > 0) {
        return this.getTableData();
      } else {
        return <Table.Row><Table.Cell colSpan={5}>No users found</Table.Cell></Table.Row>;
      }
    }
  }

  initiateRemove = (userId) => {
    this.setState({ userIdToRemove: userId, open: true });
  }

  close = () => {
    this.setState({ userIdToRemove: null, open: false });
  }

  confirmDelete = async () => {
    const { userIdToRemove } = this.state;
    let site = parseInt(localStorage.getItem('site'));
    await axios.get(`${REACT_APP_API_URL}/users/${userIdToRemove}/delete?site=${site}`);
    this.props.removeUser(userIdToRemove);
    this.setState({ userIdToRemove: null, open: false });
  }

  searchTable = (event) => {
    this.setState({ searchTerm: event.target.value, currentPage: 1, offset: 0 })
  }

  render() {
    const { todos, currentPage, dataPerPage, searchTerm, open } = this.state;
    const { users } = this.props.state.User;

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;

    var searchedData = this.props.state.User.users.filter((value) => {
      if (searchTerm == "") {
        return value
      }
      else if (value.first_name && value.first_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
      else if (value.last_name && value.last_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
      else if (value.email && value.email.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


    var data = searchedData.slice(indexOfFirstData, indexOfLastData);

    var columns = [
      {

        name: 'ID',
        selector: 'id',
        sortable: true,
        center: true,
        width: "200px"
      },
      {

        name: 'First Name',
        selector: 'first_name',
        sortable: true,
        center: true,
        width: "200px"
      },
      {
        name: 'Last Name',
        selector: 'last_name',
        sortable: true,
        center: true,
        width: "200px"

      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
        width: "200px"
      },
      {
        name: 'Pricing Plan',
        cell: row => <span>{(row.subscription) ? row.subscription.plan_id.name : '-'}</span>,
        sortable: true,
        center: true,
        width: "200px"
      },
      {
        name: 'Last Login',
        selector: 'last_login',
        sortable: true,
        center: true,
        width: "200px"
      },
      {
        name: 'Created at',
        selector: 'created_at',
        sortable: true,
        center: true,
        width: "200px"
      },
      {
        name: 'Action',
        cell: row => <Button.Group>
          {/*<Button onClick={(u) => { this.initiateRemove(row.id) }} negative>Remove</Button>
          <Button.Or />*/}
          <Button loading={this.state.loading[row.id]} onClick={(u) => { this.autoLogin(row) }}>Impersonate</Button>
          <Button.Or />
          {row.subscription ?
            (<Link to={{ pathname: `/users/${row.id}/subscription` }}><Button positive>Subscription detail</Button></Link>) :
            (<a><Button disabled positive>Subscription detail</Button></a>)}
        </Button.Group>,
        width: "500px",
        center: true,
      },

    ];


    let csvData = [["ID", "First Name", "Last Name", "Email", "Active", "Pricing Plan", "Created at"]];

    users.map(user => {

      let planName = (user.subscription) ? user.subscription.plan_id.name : '-';

      csvData.push([user.id, user.first_name, user.last_name, user.email, planName, user.created_at]);

    });

    return (
      <>
        <Confirm open={open}
          onCancel={this.close}
          onConfirm={this.confirmDelete}
          content="Are you sure?"
          size="mini" />

        <Segment>

          <div className="row mb-4 mt-2">
            <div className="col">
              <h2>
              Users
              </h2>
            </div>
            <div className="col">
              <div className="d-flex w-100 justify-content-end">
                <div>
                  <Input type="text" size='mini' icon='search' placeholder="search.... " onChange={event => { this.searchTable(event) }} />
                </div>
                <div className="ml-3">
                 <Button size='mini' primary>
                  <CSVLink filename={"users.csv"} style={{ textDecoration: 'none', color: 'white', width: '100%', paddingLeft: 10, paddingRight: 10 }} data={csvData}>Export users</CSVLink>
                 </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
          // pagination


          />
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(searchedData.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={currentPage - 1} />

        {/* {renderPageNumbers} */}
      </>
    );
  }
}


export default connect(
  state => {
    const { searchStore, User } = state;
    return {
      state: { searchStore, User }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(User);
