import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';

class EditUserForm extends Component {
  render(){

  	  const options= [{
		    key: 'active',
		    text: 'Yes',
		    value: true 
		  },
		  {
		    key: 'active',
		    text: 'No',
		    value: false
  	  }];

	  const { handleSubmit, loading } = this.props;

	  return (<Form onSubmit={handleSubmit}>
	          <label>First Name</label>
	            <Field name="first_name" component={RenderField} placeholder="First name" as={Form.Input} required/>
	          <label>Last Name</label>
	            <Field name="last_name" component={RenderField} placeholder="Last Name" as={Form.Input} required/>
	          <label>Email</label>
	            <Field name="email" component={RenderField} placeholder="Email" as={Form.Input} disabled/>
	          <label>Active</label>
	           <Field name="active" as={Form.Select} fluid lightweight options={options} component={RenderField} width={16} />
	          <label>Password</label>
	            <Field name="password" type="password" component={RenderField} placeholder="Password" as={Form.Input} />
	          <label>Confirm Password</label>
	            <Field name="confirm_password" type="password" component={RenderField} placeholder="Confirm Password" as={Form.Input} />
	          <Divider/>
	          <Form.Group>
		        <Button loading={loading} primary type="submit">Submit</Button>
	          </Form.Group>
	        </Form>)
	}
}

EditUserForm = reduxForm({
  form: 'editUserForm',
  enableReinitialize : true
})(EditUserForm)

export default EditUserForm;