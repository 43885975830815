let site= parseInt(localStorage.getItem('site'));
var modules ={};
if(site == 1)
{
    modules= {
        flip_selfie:"Flip Selfie",
        /*exp_photo:"Experiences Photo",
        exp_gif:"Experiences Gif",
        exp_boomerang:"Experiences Boomerang",
        up_gallery:"Upload Gallery",
        exp_photo:"Photo",*/
        opt_filters:"Filters",
        opt_stickers:"Stickers",
        opt_bg:"Background Removal",
        camera_btn:"Camera Button",
        share_email:"Share Email",
        share_facebook:"Share Facebook",
        share_twitter:"Share Twitter",
        up_dropbox:"Upload Dropbox",
        up_google:"Upload Google",
        gallery_check:"Gallery Check",
        image_upload_btn:'Image Upload Button',
        font: 'Font',
        frame: 'Frame'
    };
  
}
else if(site == 2)
{
    modules= {
        scheduler:"Scheduler",
        illutag_collect:"Illutag Collect",
        conditional_question:"Conditional Question",
        illutag_leaderboard:"Illutag Leaderboard",
        teamSupport:"Team Support",
        folderSupport:"Folder Support",
        transcribing:"Transcribing"
    };
}
else if(site == 3){
    modules= {
        pdf:"Pdf",
        images:"Images",
        video:"Video",
        custom:"Custom",
        embed:"Embed",
        download:"Download",
        share:"Share",
    };


}
else if(site == 4){
    modules= {
        addPagesXP:"Pages",
        addCampaignsXP:"Campaigns",
        addAgendaXP:"Agenda",
        addNotificationsXP:"Notifications",
        addTeamXP:"Teams",
        addAttendeesXP:"Attendees",
        addIntegrationXP: "Integration",
        addGamificationXP:"Gamifications",
        addReportsXP:"Reports",
        addAudienceXP:"Audience",
        addAccessCodeXP:"Access Code",
        addMeetingsXP: "Meetings",
        addOpportunityManagementXP: "Opportunity Management",
        addFileManagementXP: "File Management",
        addAiAgentXP: "AI Agent",
    };
}

export default modules;
