import React, { Component } from "react";
import {
    Button,
    Header,
    Icon,
    Message,
    Segment,
    Table,
    Divider,
    Confirm,
    Grid,
    Dimmer,
    Loader
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { actionCreatorsConfigurations } from "../../store/Configuration";
const { REACT_APP_API_URL } = process.env;
const customStyles = {
    rows: {
      style: {
        minHeight: '68px', // override the row height
        border: '0 !important',
      }
    },
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        paddingLeft: '16px', // override the cell padding for head cells
        paddingRight: '16px',
      },
    },
    cells: {
      style: {
        paddingLeft: '18px', // override the cell padding for data cells
        paddingRight: '18px',
      },
    },
  };

class Configuration extends Component {
    state = {
        offset: 0,
    }
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        this.props.getSystemAccount()
        this.setState({ loading: true })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.Configuration?.getSystemAccountSuccess && !prevProps.Configuration?.getSystemAccountSuccess) {
            this.setState({
                systemAccountEmail: this.props.Configuration?.systemAccountEmail
            })
            this.state.loading && this.handleLoading()
        }
        if (this.props.Configuration?.error && !prevProps.Configuration?.error) {
            this.state.loading && this.handleLoading()
        }
    }

    handleLoading = () => {
        let loading = this.state.loading;
        setTimeout(() => {
            this.setState({
                loading: !loading,
            });
        }, 200);
    };

    render() {
        const { systemAccountEmail } = this.state

        var data = [{ systemAccountEmail: systemAccountEmail }]

        var columns = [
            {
                name: 'System Account',
                selector: 'systemAccount',
                cell: row => <span style={{ padding: "0 0 0 10px" }}
                >{row.systemAccountEmail}</span>,
                center: false
            },

            {
                name: 'Action',
                cell: row =>
                    <Link to={{ pathname: `/configuration/change-system-account` }}><Button primary>Change</Button></Link>,
                center: true
            },
        ];

        return (
            <Segment>
                {this.state.loading ? (
                    <Dimmer active inline="centered" inverted>
                        <Loader />
                    </Dimmer>
                ) : null}
                <div>

                    <div>
                        <div className="row mb-4 mt-2">
                            <div className="col">
                                <h2>
                                    Configuration
                                </h2>
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={data}
                            customStyles={customStyles}
                            striped={true}
                            highlightOnHover={true}
                            pointerOnHover={true}
                            responsive={true}
                        />
                    </div>
                </div>
            </Segment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        Configuration: state.Configuration
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(actionCreatorsConfigurations, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
