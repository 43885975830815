import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import EditUserForm from "../forms/edit-user-form";
import { RenderField, RenderDateField } from '../common/renderField';
const { REACT_APP_API_URL }= process.env;

class EditUser extends Component {
  state={
    loading:false,
    initialValues: {},
    success:false
  }
  constructor(props) {
    super(props);
  }

  getUser = async () => {
    let site= parseInt(localStorage.getItem('site'));
    let user= await axios.get(`${REACT_APP_API_URL}/users/${this.props.match.params.id}?site=${site}`);
    return user;
  }

  componentDidMount= async () => {
    let user= await this.getUser();
    user = _.get(user,'data');
    let userCleaned = _.pickBy(user, (value, key) => key === "first_name" || key === "last_name" || key === "email" || key === "active");
    this.setState({initialValues:userCleaned});
  }

  handleSubmit = async (user) => {
   this.setState({loading:true});
   let password= _.get(user,'password');
   let cPassword= _.get(user,'confirm_password');

   if(!_.isEmpty(password) || !_.isEmpty(cPassword)){

     if(password !== cPassword){
         this.setState({loading:false});
         throw new SubmissionError({'confirm_password':'Password mismatched'});
     }
   }

   let site= parseInt(localStorage.getItem('site'));
    
   let userData= {
        first_name:_.get(user,'first_name'),
        last_name:_.get(user,'last_name'),
        email:_.get(user,'email'),
        active: _.get(user,'active'),
        password: _.get(user,'password')
   };

   try{
     let userNew= await axios.post(`${REACT_APP_API_URL}/users/${this.props.match.params.id}/patch?site=${site}`,userData);
     userNew= _.get(userNew,'data');
     this.props.editUser(userNew);
     this.setState({loading:false,success:true});
    }catch(err){
      this.setState({loading:false});
      throw new SubmissionError({error:err.message});
   }
  };

  render() {
    const { initialValues, success } = this.state;
    return (
      <Segment>
        <h1>{ initialValues.first_name } ({ initialValues.email})<span style={{fontSize:14,color:'green',marginLeft:10}}>{ success && 'User updated!!'}</span></h1>
        <Divider/>
        <EditUserForm success={success} initialValues={initialValues} loading={this.state.loading} onSubmit={this.handleSubmit}/>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { searchStore, User } = state;
    return {
      state: { searchStore, User }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(EditUser);
