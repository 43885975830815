import axios from "axios"
const URL = process.env.REACT_APP_API_URL
const Illumeet_URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL

import { reset } from "redux-form"

const GET_ALL_PAGES_REQUEST = "GET_ALL_PAGES_REQUEST"
const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS"
const GET_ALL_PAGES_ERROR = "GET_ALL_PAGES_ERROR"
const ADD_PAGE_TEMPLATES_REQUEST = "ADD_PAGE_TEMPLATES_REQUEST"
const ADD_PAGE_TEMPLATES_SUCCESS = "ADD_PAGE_TEMPLATES_SUCCESS"
const ADD_PAGE_TEMPLATES_ERROR = "ADD_PAGE_TEMPLATES_ERROR"
const LIST_PAGE_TEMPLATES_REQUEST = "LIST_PAGE_TEMPLATES_REQUEST"
const LIST_PAGE_TEMPLATES_SUCCESS = "LIST_PAGE_TEMPLATES_SUCCESS"
const LIST_PAGE_TEMPLATES_ERROR = "LIST_PAGE_TEMPLATES_ERROR"
const UPDATE_PAGE_TEMPLATES_REQUEST = "UPDATE_PAGE_TEMPLATES_REQUEST"
const UPDATE_PAGE_TEMPLATES_SUCCESS = "UPDATE_PAGE_TEMPLATES_SUCCESS"
const UPDATE_PAGE_TEMPLATES_ERROR = "UPDATE_PAGE_TEMPLATES_ERROR"
const DELETE_PAGE_TEMPLATES_REQUEST = "DELETE_PAGE_TEMPLATES_REQUEST"
const DELETE_PAGE_TEMPLATES_SUCCESS = "DELETE_PAGE_TEMPLATES_SUCCESS"
const DELETE_PAGE_TEMPLATES_ERROR = "DELETE_PAGE_TEMPLATES_ERROR"

const initialState = {
    totalResults: 0,
    allPages: [],
    listPageTemplate:[],
    message: null,
    error: false,
    getAllPagesSuccess:false,
    addPageTemplatesSuccess:false,
    listPageTemplateSuccess:false,
    updatePageTemplatesSuccess:false,
    deletePageTemplatesSuccess:false,
}

const getAllPageRequest = (data) => {
    return {
        type: GET_ALL_PAGES_REQUEST,
        data,
    }
 }

 const getAllPageSuccess = (data, message) => {
    return {
        type: GET_ALL_PAGES_SUCCESS,
        data,
        message,
    }
 }
 
 const getAllPageError = (message) => {
     return {
         type: GET_ALL_PAGES_ERROR,
         message,
     }
 }

 const addPageTemplatesRequest = (data) => {
    return {
        type: ADD_PAGE_TEMPLATES_REQUEST,
        data,
    }
 
 }

 const addPageTemplatesSuccess = (message) => {
    return {
        type: ADD_PAGE_TEMPLATES_SUCCESS,
        message,
    }
 }
 
 const addPageTemplatesError = (message) => {
     return {
         type: ADD_PAGE_TEMPLATES_ERROR,
         message,
     }
 }
 
 const listPageTemplatesRequest = (data) => {
    return {
        type: LIST_PAGE_TEMPLATES_REQUEST,
        data,
    }
 
 }
 
 const listPageTemplatesSuccess = (data,message,  totalResults,) => {
    return {
        type: LIST_PAGE_TEMPLATES_SUCCESS,
        data,
        message,
        totalResults
    }
 }
 
 const listPageTemplatesError = (message) => {
     return {
         type: LIST_PAGE_TEMPLATES_ERROR,
         message,
     }
 }

 const deletePageTemplatesRequest = (data) => {
    return {
        type: DELETE_PAGE_TEMPLATES_REQUEST,
        data,
    }
 
 }
 
 const deletePageTemplatesSuccess = (message) => {
    return {
        type: DELETE_PAGE_TEMPLATES_SUCCESS,
        message,
    }
 }
 
 const deletePageTemplatesError = (message) => {
     return {
         type: DELETE_PAGE_TEMPLATES_ERROR,
         message,
     }
 }
 
 

 const updatePageTemplateRequest = (data) => {
    return {
        type: UPDATE_PAGE_TEMPLATES_REQUEST,
        data: data
    }
}

const updatePageTemplateSuccess = (message) => {
    return {
        type: UPDATE_PAGE_TEMPLATES_SUCCESS,
        message,
    }
}

const updatePageTemplateError = (message) => {
    return {
        type: UPDATE_PAGE_TEMPLATES_ERROR,
        message
    }
}

export const actionCreatorsProjectPages = {
    getAllPages: (data) => async (dispatch, getState) => {
        dispatch(getAllPageRequest())
      
        axios.post(`${Illumeet_URL}/api/events/superadminPages`,data).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    dispatch(getAllPageSuccess(res.data.data, res.data.message))
                } else {
                    dispatch(getAllPageError(res.data.message))
                }
            } else {
                dispatch(getAllPageError(res.data.message))
            }
        })
    },

    addPageTemplates: (data) => async (dispatch, getState) => {
        dispatch(addPageTemplatesRequest())
      
        axios.post(`${URL}/pageTemplates/add `,data).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    dispatch(addPageTemplatesSuccess(res.data.message))
                } else {
                    dispatch(addPageTemplatesError(res.data.message))
                }
            } else {
                dispatch(addPageTemplatesError(res.data.message))
            }
        })
    },

    listPageTemplates: (data) => async (dispatch, getState) => {
        dispatch(listPageTemplatesRequest())
      
        axios.post(`${URL}/pageTemplates/list `,data).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    dispatch(listPageTemplatesSuccess(res.data.data,res.data.message,res.data.totalResults))
                } else {
                    dispatch(listPageTemplatesError(res.data.message))
                }
            } else {
                dispatch(listPageTemplatesError(res.data.message))
            }
        })
    },

    updatePageTemplate: (data) => async (dispatch) => {
        dispatch(updatePageTemplateRequest(data))
        axios.post(`${URL}/pageTemplates/edit `,data).then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(updatePageTemplateSuccess(res.data.message))
                    } else {
                        dispatch(updatePageTemplateError(res.data.message))
                    }
                } else {
                    dispatch(updatePageTemplateError(res.data.message))
                }
            }).catch(err => dispatch(updatePageTemplateError(err.message)))
    },

    deletePageTemplates: (data) => async (dispatch, getState) => {
        dispatch(deletePageTemplatesRequest())
      
        axios.patch(`${URL}/pageTemplates/delete`,data).then(res => {
            if(res.status === 200){
                if(res.data.status === 200){
                    dispatch(deletePageTemplatesSuccess(res.data.message))
                } else {
                    dispatch(deletePageTemplatesError(res.data.message))
                }
            } else {
                dispatch(deletePageTemplatesError(res.data.message))
            }
        })
    },


}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
            case GET_ALL_PAGES_REQUEST:
                return {
                    ...state,
                    message:null,
                    error: false,
                    getAllPagesSuccess:false,
                }
                break;
        
            case GET_ALL_PAGES_SUCCESS:
                return {
                    ...state,
                    allPages: action.data,
                    message: action.message,
                    error: false,
                    getAllPagesSuccess: true
                }
                break;
            case GET_ALL_PAGES_ERROR:
                return {
                    ...state,
                    allPages: [],
                    message: action.message,
                    error: true
                }
                break;
                      
                case ADD_PAGE_TEMPLATES_REQUEST:
                    return {
                        ...state,
                        message:null,
                        error: false,
                        addPageTemplatesSuccess:false,
                    }
                    break;
            
                case ADD_PAGE_TEMPLATES_SUCCESS:
                    return {
                        ...state,
                        message: action.message,
                        error: false,
                        addPageTemplatesSuccess: true
                    }
                    break;
                case ADD_PAGE_TEMPLATES_ERROR:
                    return {
                        ...state,
                        message: action.message,
                        error: true,
                        
                    }

                    case LIST_PAGE_TEMPLATES_REQUEST:
                        return {
                            ...state,
                            message:null,
                            error: false,
                            listPageTemplateSuccess:false,
                        }
                        break;
                
                    case LIST_PAGE_TEMPLATES_SUCCESS:
                        return {
                            ...state,
                            listPageTemplate: action.data,
                            message: action.message,
                            totalResults: action.totalResults,
                            error: false,
                            listPageTemplateSuccess: true
                        }
                        break;

                        case LIST_PAGE_TEMPLATES_ERROR:
                            return {
                                ...state,
                                listPageTemplate: [],
                                message: action.message,
                                error: true
                            }

                            case UPDATE_PAGE_TEMPLATES_REQUEST:
                                return {
                                    ...state,
                                    message:null,
                                    error: false,
                                    updatePageTemplatesSuccess:false,
                                }
                                break;
                        
                            case UPDATE_PAGE_TEMPLATES_SUCCESS:
                                return {
                                    ...state,
                                    message: action.message,
                                    error: false,
                                    updatePageTemplatesSuccess: true
                                }
                                break;
                            case UPDATE_PAGE_TEMPLATES_ERROR:
                                return {
                                    ...state,
                                    message: action.message,
                                    error: true,
                                    
                                }

                                case DELETE_PAGE_TEMPLATES_REQUEST:
                                    return {
                                        ...state,
                                        message:null,
                                        error: false,
                                        deletePageTemplatesSuccess:false,
                                    }
                                    break;
                            
                                case DELETE_PAGE_TEMPLATES_SUCCESS:
                                    return {
                                        ...state,
                                        message: action.message,
                                        error: false,
                                        deletePageTemplatesSuccess: true
                                    }
                                    break;
                                case DELETE_PAGE_TEMPLATES_ERROR:
                                    return {
                                        ...state,
                                        message: action.message,
                                        error: true,
                                        
                                    }
                    break;
            

        default: return state
            break;
    }
}