import React, { Component } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  Divider,
  Confirm,
  Grid,
  Dropdown,
  Input
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
const { REACT_APP_API_URL } = process.env;
const customStyles = {
 
  rows: {
    style: {
      minHeight: '68px', // override the row height
      // border: '0 !important',
      borderBottom: '1px solid #e3e3e3',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class DsServicePlanMapping extends Component {
  state = {
    open: false,
    offset: 0,
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    pricingPlans: [],
    servicePlans: [],
    mappingData: [],
    updatedMappingList: [],
    loading: false
  }
  constructor(props) {
    super(props);
  }

  createList = () => {
    const { mappingData, pricingPlans } = this.state;
    
    const list = pricingPlans.map(pricingPlan => {
      
      const selectedMapping = _.find(mappingData, (row) => row.pricing_plan?.id == pricingPlan?.id);
      
      let service_plan = null;

      if (selectedMapping) {
        service_plan = selectedMapping?.service_plan?._id;
      }

      return { pricing_plan: pricingPlan.id, service_plan: service_plan };
    });
    this.setState({ updatedMappingList: list });
  }

  onChange = (e, data) => {
    let pricingPlanId = _.get(data, 'value.pricing_plan');
    let servicePlanId = _.get(data, 'value.service_plan');
    let stateObj = Object.assign({}, this.state);

    stateObj.updatedMappingList = stateObj.updatedMappingList.map(row => {

      if (row.pricing_plan == pricingPlanId) {
        row.service_plan = servicePlanId;
      }
      return row;
    });
    this.setState(stateObj);
  }

  dsServicePlanMapping = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let response = await axios.get(`${REACT_APP_API_URL}/mapping?site=${site}`);
      this.setState({ pricingPlans: _.get(response, 'data.pricingPlans'), servicePlans: _.get(response, 'data.servicePlans'), mappingData: _.get(response, 'data.mappingData') });
    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.dsServicePlanMapping();
    this.createList();
  }

  listServicePlans = (pricingPlanId) => {
    const { servicePlans, updatedMappingList } = this.state;
    const servicePlanOptions = servicePlans?.map((servicePlan, key) => {
      return { key: key, text: _.get(servicePlan, 'plan_name'), value: { pricing_plan: pricingPlanId, service_plan: _.get(servicePlan, 'id') } };
    });

    const selectedMapping = _.find(updatedMappingList, (row) => row.pricing_plan == pricingPlanId);

    let defaultValue = false;

    if (selectedMapping) {
      defaultValue = { pricing_plan: pricingPlanId, service_plan: selectedMapping.service_plan };
    }

    return <Dropdown onChange={this.onChange} style={{ width: '50%' }} key={pricingPlanId} defaultValue={defaultValue} direction='downward' placeholder='Assign Service Plan' fluid selection options={servicePlanOptions} />
  }

  updateMapping = async () => {

    const { updatedMappingList } = this.state;

    this.setState({ loading: true, success: null, error: null });

    try {
      let site = parseInt(localStorage.getItem('site'));
      let response = await axios.post(`${REACT_APP_API_URL}/mapping/update?site=${site}`, { mappingData: updatedMappingList });
      this.setState({ loading: false, success: 'Mapping list updated!' });
    } catch (err) {
      console.log("Error is this = ", err.message);
      this.setState({ loading: false, error: 'Something went wrong!' });
    }



  }

  render() {
    const { todos, currentPage, dataPerPage, searchTerm, success, loading } = this.state;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentTodos = this.state.pricingPlans.slice(indexOfFirstData, indexOfLastData);
    var data = [...currentTodos].filter((value) => {
      if (searchTerm == "") {
        return value
      }
      else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


    var columns = [
      {
        name: 'Pricing Plan',
        selector: 'name',
        sortable: true,
        center: true,
      },
      {
        name: 'Service Plan',
        cell: row => {
          return this.listServicePlans(row.id)
        },
        sortable: true,
        center: true,
      },
    ];
    if (!this.state.pricingPlans) {
      return <Message>Pricing Plans Loading ...</Message>;
    }
    return (
      <div>

        <Segment>
          <div className="row mb-4 mt-2">
            <div className="col">
              <h2>
                Ds Service Plan Mapping <span style={{ fontSize: 14, color: 'green', marginLeft: 10 }}>{success && success}</span>
              </h2>
            </div>
            <div className="col">
              <div className="d-flex w-100 justify-content-end">
                <div className="ml-3">
                  <Button size='mini' loading={loading} primary onClick={this.updateMapping}>Update Mapping</Button>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
            className="table-min-height"
          />
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(this.state.pricingPlans.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} />
      </div>
    );
  }
}


export default DsServicePlanMapping;
