import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/User";
import { connect } from "react-redux";
import ProductServiceForm from "../forms/product-service-form";
import { RenderField, RenderDateField } from '../common/renderField';
const { REACT_APP_API_URL }= process.env;

class EditProductService extends Component {
  state={
    loading:false,
    products:[],
    productService:{}
  }
  constructor(props) {
    super(props);
  }

  getProductService= async () => {
    let productServiceId= this.props.match.params.id;
    try{
      let site= parseInt(localStorage.getItem('site'));
      let productService= await axios.get(`${REACT_APP_API_URL}/productService/${productServiceId}?site=${site}`);
      if(productService.data)
      {
        productService= _.get(productService,'data');
        delete productService.id;
        delete productService.updatedAt;
        delete productService.createdAt;
        this.setState({productService:productService});
      }
      else{
          this.props.history.push(`/product-services`);
      }
     
    }catch(err){
      
    }
    
  }

  products = async () => {
    try {
      let site = parseInt(localStorage.getItem('site'));
      let products = await axios.get(`${REACT_APP_API_URL}/pricing-plans?site=${site}&singlePaymentUnlimited=true`);
      this.setState({products:products.data});
    } catch (err) {

    }
  }

  componentDidMount = async () => {
     await this.getProductService();
     await this.products();
  }
  

  productOptions= () =>{
    const { products } = this.state;

    let options= products.map(product => {
        return { text: _.get(product,'name'), value: _.get(product,'id')};
    });

    return options;
  }


  handleSubmit = async (productService) => {
   this.setState({loading:true});

   if(!_.get(productService,'product')){
       this.setState({loading:false});
       throw new SubmissionError({'product':'Please select a wix product'});
   }
   productService['site']= parseInt(localStorage.getItem('site'));

   try{
     let createdProductService= await axios.post(`${REACT_APP_API_URL}/productService/patch`,productService);
     this.setState({loading:false});
     this.props.history.push(`/product-services`);
   }catch(err){
      this.setState({loading:false});
      throw new SubmissionError({error:err.message});
   }
  };

  render() {
    const { productService }= this.state;

    console.log("Product Service == ",productService);
    return (
      <Segment>
        <h1>{ (productService) ? productService.name: 'Loading ... '}</h1>
        <Divider/>
        <ProductServiceForm initialValues={productService} products={this.productOptions()} loading={this.state.loading} onSubmit={this.handleSubmit}/>
      </Segment>
    );
  }
}


export default connect(
  state => {
    return {
      state: {}
    };
  }
)(EditProductService);
