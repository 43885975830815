import React, { Component } from "react";
import {
  Button,
  Segment,
  Confirm,
  Grid,
  Modal,
  Popup,
  Loader,
  Dimmer,
  GridRow,
  Input,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/ServicePlan";

import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import modules from "../../common/modules";
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { actionCreatorsConfigurations } from "../../../store/Configuration";
import { actionCreatorsIllumeetProjects } from "../../../store/IllumeetProjects";
import { actionCreatorsProjectTemplates } from "../../../store/ProjectTemplates";
import DeleteIcon from "../../../components/assets/icons/delete.svg";
import moment from 'moment';
import { actionCreatorsProjectPages } from "../../../store/PageTemplates";

const { REACT_APP_API_URL } = process.env;
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};
class ProjectTemplates extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      open: false,
      offset: 0,
      currentPage: 1,
      dataPerPage: 10,
      searchText: "",
      sort_by: "",
      order: "desc",
      systemAccountId: "",
      page_no: 1,
      openDeleteConfirmationModal: false,
      deleteId: "",
      loading: false,
      isSuccess: false,
      successMessage: '',
      isError: false,
      errorMessage: '',
      forcePage: 0,
      projectTemplateList: [],
      responseReceived: false
    }
  }


  componentDidMount = async () => {
    this.props.getSystemAccount()
    this.setState({
      loading: true
    })
    this.setState({
      isSuccess: this.props?.location?.state?.isSuccess,
      successMessage: this.props?.location?.state?.message
    }, () => setTimeout(() => {
      this.setState({
        isSuccess: false,
        successMessage: ''
      })
    }, 5000))

    if (this.props.history.location?.state && this.props.history.location.state.message) {
      let state = { ...this.props.history.location.state };
      delete state.message;
      delete state.isSuccess;
      this.props.history.replace({ ...this.props.history.location, state });
    }
  }

  getAllProjectsTemplates = () => {
    const { searchText, sort_by, order, systemAccountId, page_no } = this.state
    const requestBody = {
      searchText,
      sort_by,
      order,
      systemAccountId,
      page_no,
    }
    this.setState({loading: true})
    this.props.getAllProjectsTemplates(requestBody)
  }

  componentDidUpdate(prevProps) {
    if (this.props.Configuration?.getSystemAccountSuccess && !prevProps.Configuration?.getSystemAccountSuccess) {
      this.setState({
        systemAccountId: this.props.Configuration?.systemAccountId
      }, () => this.getAllProjectsTemplates())
    }

    if (this.props.ProjectTemplates?.getAllProjectsTemplatesSuccess && !prevProps.ProjectTemplates?.getAllProjectsTemplatesSuccess) {
      this.setState({
        projectTemplateList: this.props.ProjectTemplates?.projectTemplateList,
        totalResults: this.props.ProjectTemplates?.totalResults,
      }, () => {
        this.state.loading && this.handleLoading()
        this.setState({
          responseReceived: true
        })
      })
      
    }

    if (this.props.PageTemplates?.getAllPagesSuccess && !prevProps.PageTemplates?.getAllPagesSuccess) {
      this.setState({
        allPagesList: this.props.PageTemplates?.allPages,
      })
      this.state.loading && this.handleLoading()
    }

    if (this.props.ProjectTemplates?.deleteProjectTemplateSuccess && !prevProps.ProjectTemplates?.deleteProjectTemplateSuccess) {
      this.closeDeleteConfirmationModal()
      this.setState({
        page_no: 1
      }, () => this.getAllProjectsTemplates())
      this.state.loading && this.handleLoading()
      this.setState({
        successMessage: this.props.ProjectTemplates?.message,
        isSuccess: true
      }, () => this.setMessageToBlank())

    }

    if (this.props.ProjectTemplates?.error && !prevProps.ProjectTemplates?.error) {
      this.state.loading && this.handleLoading()
      this.setState({
        errorMessage: this.props.ProjectTemplates?.message,
        isError: true,
        responseReceived: true
      }, this.setMessageToBlank())

    }

    if (this.props.Configuration?.error && !prevProps.Configuration?.error) {
      this.state.loading && this.handleLoading()
      this.setState({
        responseReceived: true
      })
    }
  }

  setMessageToBlank = () => {
    setTimeout(() => {
      this.setState({
        successMessage: '',
        isSuccess: false,
        errorMessage: '',
        isError: false,
      })
    }, 5000);
  }

  handleClick(event) {
    const offset = event.selected * this.state.dataPerPage
    this.setState({
      currentPage: Number(event.selected + 1),
      offset: offset
    });
  }

  handlePageChange = (e) => {
    const offset = e.selected * this.state.dataPerPage
    this.setState({
      currentPage: Number(e.selected + 1),
      offset: offset
    });
  }

  open = (planId) => {
    this.setState({ planToDelete: planId, open: true });
  }

  getServicePlanName = (row) => {
    // return row.servicePlans.map(el => el.plan_name)
    return row?.servicePlanNames?.map(el => el)
  }

  search = (e) => {
    this.setState({
      searchText: e.target.value,
      currentPage: 1,
      offset: 0,
      forcePage: 0,
    }, () => this.getAllProjectsTemplates())
  }

  openDeleteConfirmationModal = (id, row) => {
    this.setState({
      openDeleteConfirmationModal: true,
      deleteId: row._id,
      projectIdForDeletePageThumbnails: row._id
    })
    this.props.getAllPages({ projectId: row._id })
  }
  closeDeleteConfirmationModal = () => {
    this.setState({
      openDeleteConfirmationModal: false,
      deleteId: ""
    })
  }

  deleteProjectTemplate = () => {
    this.props.deleteProjectTemplate({ id: this.state.deleteId })
    this.setState({
      loading: true
    })
    let allPagesIdsFromSelectedProject = this.state.allPagesList?.map(page => page._id)

    const formData = new FormData()
      formData.append('projectId', this.state.projectIdForDeletePageThumbnails)
      formData.append('noThumbnails', JSON.stringify(allPagesIdsFromSelectedProject))
      formData.append('projectTemplateTitle', '')
      formData.append('description', '')
      formData.append('servicePlanNames', JSON.stringify([]))
      formData.append('servicePlanIds', JSON.stringify([]))
      this.props.appendProjectTemplatesDetailsInIllumeet(formData)
  }

  getRemainingServicePlans = (row) => {
    // let newArr = row.servicePlans.map(el => el.plan_name)
    let newArr = row?.servicePlanNames?.map(el => el)
    let remainingServicePlans = newArr?.slice(1, newArr.length + 1)
    if (remainingServicePlans.length > 0) {
      return (
        <div>
          {remainingServicePlans.map(el => {
            return (
              <p>{el}</p>
            )
          })}
        </div>
      )
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    setTimeout(() => {
      this.setState({
        loading: !loading,
      });
    }, 200);
  };

  render() {
    const { todos, currentPage, dataPerPage, searchText, open, projectTemplateList, totalResults } = this.state;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const data = Array.isArray(projectTemplateList) && projectTemplateList.slice(indexOfFirstData, indexOfLastData);

    var columns = [
      {
        name: 'Project',
        selector: row => row?.eventName,
        cell: row => <span>{row?.eventName}</span>,
        sortable: true,
        center: false,
      },
      {
        name: 'Template Title',
        selector: row => row?.projectTemplateTitle,
        cell: row => <span>{row.projectTemplateTitle}</span>,
        sortable: true,
        center: false
      },
      {
        name: 'Project Type',
        selector: row => row?.eventType,
        cell: row => <span>{row.eventType}</span>,
        sortable: true,
        center: false
      },
      {
        name: 'Service Plan',
        cell: row => this.getServicePlanName(row).length > 1 ? (
          <Popup trigger={<span className="service-plan">{this.getServicePlanName(row)[0]} <span className="count">+{this.getServicePlanName(row).length - 1}</span></span>}
            position='bottom left'>
            {this.getRemainingServicePlans(row)}
          </Popup>) :
          <span className="service-plan">
            {this.getServicePlanName(row)}
          </span>,
        center: false
      },
      {
        name: 'Updated On',
        selector: row => row.updatedDateForSuperAdmin
        ,
        cell: row => <span>{moment(row.updatedDateForSuperAdmin
          ).format('lll')}</span>,
        sortable: true,
        center: false
      },
      {
        name: 'Action',
        cell: row =>
          <div className="d-flex">
            <Link to={{ pathname: `/project-templates/edit`, state: { row: row } }}>
              <Button size='mini' primary>Edit</Button>
            </Link>
            <Button size='mini' color='red' style={{ paddingTop: '8px', paddingBottom: '6px', }} onClick={() => this.openDeleteConfirmationModal(row._id, row)}><img alt="Delete" height={'15'} src={DeleteIcon} /></Button>
          </div>,
        center: true
      },
    ];

    return (
      <Segment>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}

        <div>
          <Confirm size='tiny' open={open} onCancel={this.close} onConfirm={this.deleteServicePlan} />

          <div>
            <div className="row mb-4 mt-2 ml-0">
              <div className="col">
                <h2> Project Templates
                  {
                    this.state.isSuccess &&
                    <span classNmae="ml-2" style={{ fontSize: 14, color: 'green', marginLeft: 10 }}>
                      {this.state.isSuccess && this.state.successMessage}
                    </span>
                  }
                  {
                    this.state.isError &&
                    <span classNmae="ml-2" style={{ fontSize: 14, color: 'red', marginLeft: 10 }}>
                      {this.state.isError && this.state.errorMessage}
                    </span>
                  }
                </h2>
              </div>
              <div className="col">
                <div className="d-flex w-100 justify-content-end">
                  <div>
                    <Input type="text" size='mini' icon='search' placeholder="search.... " className="search" onChange={this.search} />
                  </div>
                  <div className="ml-3">
                    <Link to='/project-templates/add'><Button size='mini' primary>Add</Button></Link>
                  </div>
                </div>
              </div>
            </div>
            {this.state.responseReceived && <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              striped={true}
              highlightOnHover={true}
              pointerOnHover={true}
              responsive={true}
            />}
          </div>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(projectTemplateList?.length / dataPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.forcePage}
          />
          <Modal
            onClose={this.closeDeleteConfirmationModal}
            onOpen={this.openDeleteConfirmationModal}
            open={this.state.openDeleteConfirmationModal}
          >
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>
                  Are you sure you want to delete?
                </p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button type="text" onClick={this.closeDeleteConfirmationModal}>
                Cancel
              </Button>
              <Button primary type="text" onClick={this.deleteProjectTemplate}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Segment>
    );
  }
}

export default connect(
  state => {
    const { ServicePlan, IllumeetProjects, Configuration, PageTemplates } = state;
    return {
      Configuration: state.Configuration,
      IllumeetProjects: IllumeetProjects,
      ServicePlan: state.ServicePlan,
      ProjectTemplates: state.ProjectTemplates,
      ServicePlanFiltered: state.ServicePlanFiltered,
      PageTemplates: state.PageTemplates
    };
  },
  dispatch => bindActionCreators(Object.assign({}, actionCreators, actionCreatorsProjectTemplates, actionCreatorsConfigurations, actionCreatorsProjectTemplates, actionCreatorsProjectPages, actionCreatorsIllumeetProjects), dispatch)
)(ProjectTemplates);

