import React, { Component } from "react";
import {
	Button,
	Divider,
	Input,
	Form
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';
import plans from '../common/plans';
import axios from 'axios';
import { Grid } from "semantic-ui-react";

const { REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL } = process.env;

class ServicePlanForm extends Component {
	state = {
		modulesFromIllumeetXP: null
	}

	componentDidMount= async() =>{
		try {
			const getModulesFromIllumeetXP = async () => {
			
				const URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL;
				const response = await axios.get(`${URL}/api/superadmin/service-plans/modules/get`);
				let keys= Object.keys(response.data.modules)
				let moduleData = keys.map(k => {
				  return <Grid.Column>
					<Field name={k} component={RenderField} as={Form.Checkbox} label={response.data.modules[k]} />
				  </Grid.Column>;
				});
				return <Grid columns='6' style={{ minHeight: '5vh' }}>{moduleData}</Grid>;
			}
			const modulesArr = await getModulesFromIllumeetXP()
			this.setState({
			  modulesFromIllumeetXP: modulesArr
			})	
		} catch (error) {
			console.log(error)
		}
	}

	render() {

		const { handleSubmit, loadModules, loading, site, loadToggles } = this.props;

		return (<Form onSubmit={handleSubmit}>
			<label>Service Plan Name</label>
			<Field name="plan_name" component={RenderField} placeholder="Service Plan Name" as={Form.Input} required />
			{site == 1 && <>
				<label>Total Number Of Events</label>
				<Field name="event_number" component={RenderField} placeholder="Total Number Of Events" as={Form.Input} required />

				<label>Total Number Of Snaps</label>
				<Field name="snap_number" component={RenderField} placeholder="Total Number Of Snaps" as={Form.Input} required />

				<label>Total Number Of Bg Removals</label>
				<Field name="bg_removal_number" component={RenderField} placeholder="Total Number Of Bg Removals" as={Form.Input} required />

				<label>Select Frame Plan</label>
				<Field name="frame_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Frame Plan' width={16} />

				<label>Select Sticker Plan</label>
				<Field name="sticker_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Sticker Plan' width={16} />

				<label>Select Background Plan</label>
				<Field name="background_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Background Plan' width={16} />
			</>}
			{site == 2 && <>
				<label>Max Minutes</label>
				<Field name="max_mintues" component={RenderField} placeholder="Max Minutes" as={Form.Input} required />

				<label>Max Steps</label>
				<Field name="max_step" component={RenderField} placeholder="Max Steps" as={Form.Input} required />
			</>}
			{site == 4 && <>
				<label>Number of Projects</label>
				<Field name="noOfXPProjects" component={RenderField} placeholder="Number of Projects" as={Form.Input} required />

				<label>Number of Contacts</label>
				<Field name="noOfXPContacts" component={RenderField} placeholder="Number of Contacts" as={Form.Input} required />

				<label>Campaigns No./ Project</label>
				<Field name="noOfXPCampaigns" component={RenderField} placeholder="Campaigns No./ Project" as={Form.Input} required />

				<label>Sessions No./ Project</label>
				<Field name="noOfXPSessions" component={RenderField} placeholder="Sessions No./ Project" as={Form.Input} required />

				<label>Notifications No./ Project</label>
				<Field name="noOfXPNotifications" component={RenderField} placeholder="Notifications No./ Project" as={Form.Input} required />

				<label>Attendees No./ Project</label>
				<Field name="noOfXPAttendees" component={RenderField} placeholder="Attendees No./ Project" as={Form.Input} required />

				<label>Custom Fields No./ Project</label>
				<Field name="noOfXPCustomFields" component={RenderField} placeholder="Custom Fields No./ Project" as={Form.Input} required />

				<label>Challenges No./ Project</label>
				<Field name="noOfXPChallenges" component={RenderField} placeholder="Challenges No./ Project" as={Form.Input} required />

				<label>Pages No./ Project</label>
				<Field name="noOfXPPages" component={RenderField} placeholder="Pages No./ Project" as={Form.Input} required />

				<label>Team Members No./ Project</label>
				<Field name="noOfXPTeamMembers" component={RenderField} placeholder="Team Members No./ Project" as={Form.Input} required />

			</>}
			<label>Modules</label>
			{site == 4 ? this.state.modulesFromIllumeetXP: loadModules()}
			{loadToggles()}
			<Divider />
			<Form.Group>
				<Button loading={loading} primary type="submit">Submit</Button>
			</Form.Group>
		</Form>)
	}
}

ServicePlanForm = reduxForm({
	form: 'servicePlanForm',
	enableReinitialize: true
})(ServicePlanForm)

export default ServicePlanForm;