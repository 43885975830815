import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { Dropdown } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import TextIcon from "../extension/TextIcon";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/SideMenu";

import Dashboard from "../assets/icons/dashboard.svg"
import Users from "../assets/icons/users.svg"
import PricingPlan from "../assets/icons/pricing-plans.svg"
import ServicePlan from "../assets/icons/service-plans.svg"
import Mapping from "../assets/icons/mapping.svg"
import Template from "../assets/icons/templates.svg"
import Configuration from "../assets/icons/configuration.svg"

const options = [
    { key: 1, text: 'Project Templates', value: 1, as: Link, to: '/project-templates' },
    { key: 2, text: 'Page Templates', value: 2, as: Link, to: '/page-templates' },
    { key: 3, text: 'Campaign Templates', value: 3, as: Link, to: '/campaign-templates' }
]
class SideMenu extends Component {
    state = {
        activeItem: 'dashboard',
    };

    handleItemClick = (e, { name }) => this.setState({ activeItem: name });
    changeSize = () => this.setState({ smallSidebar: !this.props.smallMenu });

    handleDropdown = (e) => {
        e.preventDefault()
    }



    getMenu() {
        let site = localStorage.getItem('site');
        const { activeItem } = this.state;
        return (
            <Menu fixed='left' borderless className={(this.props.smallMenu ? 'small-side' : '') + ' side'} vertical>
                <Menu.Item as={Link} to={'/'} name='dashboard' active={activeItem === 'dashboard'}
                    onClick={this.handleItemClick}>
                    <TextIcon hideText={this.props.smallMenu} color='teal' icon={Dashboard}>
                        Dashboard
                    </TextIcon>
                </Menu.Item>

                <Menu.Item
                    as={Link} to={'/users'}
                    name='users'
                    active={activeItem === 'users'}
                    onClick={this.handleItemClick}
                >
                    <TextIcon hideText={this.props.smallMenu} icon={Users}>
                        Users
                    </TextIcon>
                </Menu.Item>

                <Menu.Item
                    as={Link} to={'/pricing-plans'}
                    name='pricing-plans'
                    active={activeItem === 'pricing-plans'}
                    onClick={this.handleItemClick}
                >
                    <TextIcon hideText={this.props.smallMenu} icon={PricingPlan}>
                        Pricing Plans
                    </TextIcon>
                </Menu.Item>

                <Menu.Item
                    as={Link} to={'/service-plans'}
                    name='service-plans'
                    active={activeItem === 'service-plans'}
                    onClick={this.handleItemClick}
                >
                    <TextIcon hideText={this.props.smallMenu} icon={ServicePlan}>
                        Service Plans
                    </TextIcon>
                </Menu.Item>

                <Menu.Item
                    as={Link} to={'/ds-service-plan-mapping'}
                    name='ds-service-plan-mapping'
                    active={activeItem === 'ds-service-plan-mapping'}
                    onClick={this.handleItemClick}
                >
                    <TextIcon hideText={this.props.smallMenu} icon={Mapping}>
                        Mapping
                    </TextIcon>
                </Menu.Item>


                {site == 4 && 

                    <Dropdown item className={`sidebar-dropdown ${activeItem === 'template' ? 'active' : ''}`} 
                        name='template'
                        onClick={this.handleItemClick}
                    text={
                        <TextIcon icon={Template}>
                            Templates
                        </TextIcon>
                    } options={options} />
                }
                {site == 4 && <Menu.Item
                    name='configuration'
                    as={Link} to={'/configuration'}
                    active={activeItem === 'configuration'}
                    onClick={this.handleItemClick}
                >
                    <TextIcon hideText={this.props.smallMenu} icon={Configuration}>
                        Configuration
                    </TextIcon>
                </Menu.Item>}

                {site == 1 &&

                    <>
                        <Menu.Item
                            as={Link} to={'/frames'}
                            name='frames'
                            active={activeItem === 'frames'}
                            onClick={this.handleItemClick}
                        >
                            <TextIcon hideText={this.props.smallMenu} name='file image'>
                                Frames
                            </TextIcon>
                        </Menu.Item>

                        <Menu.Item
                            as={Link} to={'/stickers'}
                            name='stickers'
                            active={activeItem === 'stickers'}
                            onClick={this.handleItemClick}
                        >
                            <TextIcon hideText={this.props.smallMenu} name='file image outline'>
                                Stickers
                            </TextIcon>
                        </Menu.Item>

                        <Menu.Item
                            as={Link} to={'/backgrounds'}
                            name='backgrounds'
                            active={activeItem === 'backgrounds'}
                            onClick={this.handleItemClick}
                        >
                            <TextIcon hideText={this.props.smallMenu} name='image outline'>
                                Backgrounds
                            </TextIcon>
                        </Menu.Item>

                        <Menu.Item
                            as={Link} to={'/credits'}
                            name='credits'
                            active={activeItem === 'credits'}
                            onClick={this.handleItemClick}
                        >
                            <TextIcon hideText={this.props.smallMenu} name='copyright'>
                                Credits
                            </TextIcon>
                        </Menu.Item>

                        <Menu.Item
                            as={Link} to={'/credits-configuration'}
                            name='credits-configuration'
                            active={activeItem === 'credits-configuration'}
                            onClick={this.handleItemClick}
                        >
                            <TextIcon hideText={this.props.smallMenu} name='folder'>
                                Credits Configuration
                            </TextIcon>
                        </Menu.Item>
                    </>
                }

            </Menu>
        )
    }

    render() {
        return (
            <div className='parent'>
                <div className={(this.props.smallMenu ? 'small-side ' : '') + 'side'}>
                    {this.getMenu()}
                </div>
                <div className={(this.props.smallMenu ? 'small-content ' : '') + 'content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default connect(
    state => state.sideMenu,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SideMenu);
