import React, {
  Component
} from 'react'
import {
  Icon,
  Image,
  Label,
  Menu,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  actionCreators as sideAction
} from "../../store/SideMenu";
import {
  actionCreators as searchAction
} from "../../store/SearchStore";
import {
  bindActionCreators
} from "redux";
import {
  connect
} from 'react-redux';

class TopMenu extends Component {
  state = {
    defaultSelected: (localStorage.getItem('site')) ? localStorage.getItem('site') : "1"
  };

  logout() {
    localStorage.removeItem('authorization');
    window.location.href = '/login';
  }

  options = () => {
    return [{
      value: "1",
      text: 'Illusnap'
    }, {
      value: "2",
      text: 'Illutag'
    }, {
      value: "3",
      text: 'Illuflip'
    }, {
      value: "4",
      text: 'IllumeetXP'
    }];
  }

  changeSite = (e, {
    value
  }) => {
    localStorage.setItem('site', value);
    this.setState({
      defaultSelected: value
    }, () => {
      window.location.reload(false);
    });
  }

  render() {
    console.log(this.state.defaultSelected);
    return ( <
      Menu fixed = "top"
      className = "top-menu" >
      <
      Menu.Item className = "logo-space-menu-item" >
      <
      div className = "display-inline logo-space" >
      <
      p > Super Admin < /p> <
      /div> <
      /Menu.Item>

      <
      Menu.Item className = "no-border"
      onClick = {
        this.props.actions.toggleSideMenu
      } >
      <
      Icon name = "bars" / >
      <
      /Menu.Item>

      <
      Menu.Menu position = "right" >
      <
      Menu.Item className = "no-border"
      position = "right" >
      <
      label style = {
        {
          marginRight: 10,
          marginBottom: 0
        }
      } > Default Website < /label> <
      Dropdown defaultValue = {
        this.state.defaultSelected
      }
      onChange = {
        this.changeSite
      }
      selection placeholder = 'Select Site'
      options = {
        this.options()
      }
      /> <
      /Menu.Item>

      <
      Menu.Item className = "no-border"
      position = "right" >
      <
      div className = "display-inline" >
      <
      Button icon labelPosition = 'left'
      onClick = {
        this.logout
      } > Logout <
      Icon name = 'logout' / > < /Button> <
      /div> <
      /Menu.Item> <
      /Menu.Menu> <
      /Menu>
    );
  }
}

export default connect(
  state => state.sideMenu,
  dispatch => {
    return {
      actions: bindActionCreators(Object.assign({}, sideAction, searchAction), dispatch)
    }
  }
)(TopMenu);