import React, { Component } from "react";
import { Segment, Divider, Form, Message, Grid, Icon, Dimmer, Loader } from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import modules from "../common/modules";
import { actionCreators } from "../../store/ServicePlan";
import { connect } from "react-redux";
import ServicePlanForm from "../forms/service-plan-form";
import { RenderField, RenderDateField, renderToggleInput } from '../common/renderField';
import toggles from "../common/toggles";
const { REACT_APP_API_URL, REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL } = process.env;
class EditServicePlan extends Component {
  state = {
    servicePlan: {},
    loading: false,
    alreadyExist: ''
  }

  constructor(props) {
    super(props);
  }
  servicePlan = async () => {
    let servicePlanId = this.props.match.params.id;
    try {
      let site = parseInt(localStorage.getItem('site'));
      let URL;
      if(site == 4) {
        URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      let servicePlan = await axios.get(`${URL}/service-plans/${servicePlanId}?site=${site}`);
      if (servicePlan.data) {
        servicePlan = _.get(servicePlan, 'data');
        delete servicePlan.id;
        delete servicePlan.updatedAt;
        delete servicePlan.createdAt;
        this.setState({ servicePlan: servicePlan });
      }
      else {
        this.props.history.push(`/service-plans`);
      }

    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.servicePlan();
  }

  handleSubmit = async (servicePlan) => {
    this.setState({ loading: true, success: false });
    let servicePlanId = this.props.match.params.id;
    try {
      let site = parseInt(localStorage.getItem('site'));
      let URL;
      if(site == 4) {
        URL = REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL
      } else {
        URL = REACT_APP_API_URL
      }
      let servicePlanUpdated = await axios.patch(`${URL}/service-plans/${servicePlanId}`, servicePlan);
      let status = servicePlanUpdated.status
      if(status === 200){
        if(servicePlanUpdated.data.status === 402){
          this.setState({ loading: false, alreadyExist: 'A service plan with the same name already exist', success: '' });
        } else {
          servicePlanUpdated = _.get(servicePlanUpdated, 'data');
          this.props.editService_Plan(servicePlanUpdated);
          this.setState({ success: 'Service plan updated', alreadyExist: '' });
        }
      }
    } catch (err) {
      this.setState({ loading: false,  });
      this.setState({alreadyExist: "Service", somethingWentWrong: 'Some thing went wrong', success: ''})
    }
  };

  loadModules = () => {
    let keys = Object.keys(modules);
    let moduleData = keys.map(k => {
      return <Grid.Column>
        <Field name={k} component={RenderField} as={Form.Checkbox} label={modules[k]} />
      </Grid.Column>;
    });
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{moduleData}</Grid>;
  }

  loadToggles = () => {
    let keys = Object.keys(toggles)
    let toggleData = keys.map(k => {
      return (
        <Grid.Column>
          <label style={{display: 'block'}}>{toggles[k]}</label>
          <Field name={k} component={renderToggleInput} label={toggles[k]} as={Form.Checkbox}/>
        </Grid.Column>
      )
    })
    return <Grid columns='6' style={{ minHeight: '5vh' }}>{toggleData}</Grid>
  }

  goBack = () => {
    this.props.history.push({ pathname: '/service-plans'})
  }

  render() {
    const { success, somethingWentWrong, alreadyExist } = this.state;
    if(success){
      setTimeout(() => {
        this.goBack()
        this.setState({
          loading: false
        })
      }, 1000);
    }

    let site = parseInt(localStorage.getItem('site'));
    return (
      
      <Segment>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}
        <div>
        
        <h1><Icon style={{ cursor: 'pointer', display: 'inline'}} name='chevron left' color='black' size='small' onClick={() => this.goBack()} />{this.state.servicePlan.plan_name} <span style={{ fontSize: 14, color: alreadyExist ? 'red' : 'green', marginLeft: 10 }}>{success ? 'Service plan updated!!' : alreadyExist !== '' ? alreadyExist : somethingWentWrong ? somethingWentWrong : ''}</span></h1>
        </div>
        
        <Divider />
        <ServicePlanForm site={site} loading={this.state.loading} initialValues={this.state.servicePlan} onSubmit={this.handleSubmit} loadModules={this.loadModules} loadToggles={this.loadToggles}/>
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { searchStore, ServicePlan } = state;
    return {
      state: { searchStore, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(EditServicePlan);
