import React, { Component } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  Divider,
  Confirm,
  Grid,
  Dropdown
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import AddImage from '../modals/addImage';
import plans from '../common/plans';
const { REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL, REACT_APP_S3_IMAGE_URL_ILLUSNAP } = process.env;
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class Frame extends Component {
  state = {
    offset: 0,
    currentPage: 1,
    dataPerPage: 10,
    searchTerm: "",
    frames:[],
    open: false,
    defaultSelected: 1

  }
  constructor(props) {
    super(props);
    this.handleClick= this.handleClick.bind(this);
  }
  frames = async () => {
    try {
      let frames = await axios.get(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/admin/get-library/frame?plan=${this.state.defaultSelected}`);
      this.setState({frames:frames.data.data, offset: 0, currentPage: 1});
    } catch (err) {
      
    }
  }
  componentDidMount = async () => {
    await this.frames();
  }

  handleClick(event) {
    const offset = event.selected * this.state.dataPerPage
    this.setState({
      currentPage: Number(event.selected + 1),
      offset: offset
    });
  }

  remove= async () => {
    const { _id }= this.state;
    
    try {
      await axios.get(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/admin/delete-library/${_id}/frame`);
      this.setState({open: false});
      this.frames();
    } catch (err) {
      
    }
  }

  open= async (_id) => {
    this.setState({_id: _id, open: true});
  }

  close= () => {
    this.setState({_id: null, open: false});
  }

  changePlanType = async (e,data) => {
    this.setState({defaultSelected:data.value},async ()=>{
      await this.frames();
    });
  }

  render() {
    const { todos, currentPage, dataPerPage, searchTerm, open } = this.state;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const data = this.state.frames.slice(indexOfFirstData, indexOfLastData);


    var columns = [
      {
        name: 'Frame',
        cell: row => <div style={{padding:10,width:80,height:80}}><img style={{width:'100%'}} src ={`${REACT_APP_S3_IMAGE_URL_ILLUSNAP}/${row.frame}`}/></div>,
        sortable: true,
        center:true
      },
      {
        name: 'Created at',
        cell: row => <span>{moment(row.created_at).format('lll')}</span>,
        sortable: true,
        center:true
      },
      {
        name: 'Action',
        cell: row => <Button secondary onClick={() => this.open(row._id)}>Remove</Button>,
        sortable: true,
        center:true
      }
    ];
    if (!this.state.frames) {
      return <Message>Frames Loading ...</Message>;
    }
    return (
      <div>
        
        <Segment>
        <Grid style={{minHeight:0}}>
        <Grid.Column width={10}>
          <h1>Frames</h1>
        </Grid.Column>
        <Grid.Column width={4}>
        <label style={{marginRight:10,marginBottom:0}}>Select a plan</label>
            <Dropdown defaultValue={this.state.defaultSelected} onChange={this.changePlanType} selection placeholder='Select Plan' options={plans} />
        </Grid.Column> 
        <Grid.Column width={2} style={{textAlign: 'right'}}>
          <Confirm open={open} onCancel={this.close} onConfirm={this.remove}/>
          <AddImage plan={this.state.defaultSelected} buttonText='Add Frame' type={'frame'} refresh={this.frames}/>
        </Grid.Column>
        </Grid>
          <DataTable
            columns={columns}
            data={data} 
            customStyles={customStyles}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            responsive={true}
            />
        </Segment>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(this.state.frames.length / dataPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handleClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"} 
          forcePage={currentPage-1}/>
      </div>
    );
  }
}


export default Frame;
