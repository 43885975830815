import React, { Component } from "react";
import { Button, Modal, Header, Icon, Popup, Message, Segment, Dimmer, Table, Divider, Confirm, Grid, Loader, Input } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreatorsProjectPages } from "../../../store/PageTemplates";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import modules from "../../common/modules";
import axios from "axios";
import _ from "lodash";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { raw } from "file-loader";
const { REACT_APP_API_URL } = process.env;
import { Dropdown, Menu } from "semantic-ui-react";
import { actionCreatorsConfigurations } from "../../../store/Configuration";
import DeleteIcon from "../../../components/assets/icons/delete.svg";
import moment from "moment";
import { actionCreatorsIllumeetProjects } from "../../../store/IllumeetProjects";
import { actionCreators } from "../../../store/ServicePlan";
const customStyles = {
  rows: {
    style: {
      minHeight: '68px', // override the row height
      border: '0 !important',
    }
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '18px', // override the cell padding for data cells
      paddingRight: '18px',
    },
  },
};

class PageTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      offset: 0,
      page_no: 1,
      currentPage: 1,
      dataPerPage: 10,
      listPageTemplate: [],
      servicePlan: [],
      searchText: "",
      sort_by: "",
      order: "desc",
      systemAccountId: "",
      openDeleteConfirmationModal: false,
      pageTemplateId: "",
      loading: false,
      isSuccess: false,
      successMessage: "",
      isError: false,
      errorMessage: "",
      forcePage: 0,
    };
  }
  componentDidMount = async () => {
    this.props.getSystemAccount();
    this.setState({
      loading: true
    })
    this.setState(
      {
        isSuccess: this.props?.location?.state?.isSuccess,
        successMessage: this.props?.location?.state?.message,
      },
      () =>
        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: "",
          });
        }, 5000)
    );

    if (this.props.history.location?.state && this.props.history.location.state.message) {
      let state = { ...this.props.history.location.state };
      delete state.message;
      delete state.isSuccess;
      this.props.history.replace({ ...this.props.history.location, state });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      this.props.Configuration.getSystemAccountSuccess === true &&
      prevProps.Configuration.getSystemAccountSuccess === false &&
      this.props.Configuration.message !== prevProps.Configuration.message
    ) {
      this.setState(
        {
          systemAccountId: this.props?.Configuration?.systemAccountId,
        },
        () => this.listPageTemplates()
      );
    }
    if (
      this.props.PageTemplates.listPageTemplateSuccess === true &&
      prevProps.PageTemplates.listPageTemplateSuccess === false &&
      this.props.PageTemplates.message !== prevProps.PageTemplates.message
    ) {
      this.setState({
        listPageTemplate: this.props?.PageTemplates?.listPageTemplate,
        totalResults: this.props?.PageTemplates?.totalResults,
      }, () => {
        this.setState({
          loading: false,
          responseReceived: true
        })
      }
      );
    }
    if (this.props.PageTemplates?.error && !prevProps.PageTemplates?.error) {
      this.state.loading && this.handleLoading();
      this.setState({
        errorMessage: this.props.PageTemplates?.message,
        isError: true,
      });
      this.setMessageToBlank();
    }

    if (
      this.props.PageTemplates.deletePageTemplatesSuccess === true &&
      prevProps.PageTemplates.deletePageTemplatesSuccess === false &&
      this.props.PageTemplates.message !== prevProps.PageTemplates.message
    ) {
      this.setState(
        {
          openDeleteConfirmationModal: false,
          pageTemplateId: "",
          page_no: 1,
        },
        () => {
          this.listPageTemplates()
          let formData = new FormData()
          formData.append('pageThumb', "")
          formData.append('projectId', this.state.projectIdForPageThumbToDelete,)
          formData.append('pageId', this.state.thumbnailPageIdToDelete)
          this.props.uploadPageTemplatesThumbnails(formData)
        }
      );
      this.setState(
        {
          successMessage: this.props.PageTemplates?.message,
          isSuccess: true,
        },
        () => this.setMessageToBlank()
      );
    }

    if (this.props.PageTemplates?.error && !prevProps.PageTemplates?.error) {
      this.state.loading && this.handleLoading()
      this.setState({
        responseReceived: true
      })
    }

    if (this.props.Configuration?.error && !prevProps.Configuration?.error) {
      this.state.loading && this.handleLoading()
      this.setState({
        responseReceived: true
      })
    }

  };

  setMessageToBlank = () => {
    setTimeout(() => {
      this.setState({
        successMessage: "",
        isSuccess: false,
      });
    }, 5000);
  };

  listPageTemplates = () => {
    try {
      const { searchText, sort_by, order, systemAccountId, page_no } = this.state;

      const body = {
        searchText,
        sort_by,
        order,
        systemAccountId,
        page_no,
      };
      this.setState({ loading: true })
      this.props.listPageTemplates(body);
    } catch (error) {
      return error;
    }
  };
  handlePageChange = (e) => {
    this.setState(
      {
        page_no: e.selected + 1,
      },
      () => this.listPageTemplates()
    );
  };

  search = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        currentPage: 1,
        offset: 0,
        forcePage: 0,
      },
      () => this.listPageTemplates()
    );
  };

  openDeleteConfirmationModal = (id, row) => {
    this.setState({
      openDeleteConfirmationModal: true,
      pageTemplateId: id,
      thumbnailPageIdToDelete: row.pages.pageId,
      projectIdForPageThumbToDelete: row.project.projectId
    });
  };

  closeDeleteConfirmationModal = () => {
    this.setState({
      openDeleteConfirmationModal: false,
      pageTemplateId: "",
      thumbnailPageIdToDelete: '',
      projectIdForPageThumbToDelete: ''
    });
  };

  deletePageTemplates = () => {
    this.props.deletePageTemplates({ pageTemplateId: this.state.pageTemplateId });
    this.handleLoading();
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  getServicePlanName = (row) => {
    return row.servicePlanId?.map((el) => el.plan_name);
  };

  getRemainingServicePlans = (row) => {
    let newArr = row.servicePlanId.map((el) => el.plan_name);
    let remainingServicePlans = newArr.slice(1, newArr.length + 1);
    if (remainingServicePlans.length > 0) {
      return (
        <div>
          {remainingServicePlans.map((el) => {
            return <p>{el}</p>;
          })}
        </div>
      );
    }
  };

  render() {
    const { totalResults, todos, currentPage, dataPerPage, searchText, open, listPageTemplate } = this.state;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;

    var columns = [
      {
        name: "Page",
        selector: (row) => row.pages.pageName,
        cell: (row) => <span>{row.pages.pageName}</span>,
        sortable: true,
        center: true,
      },

      {
        name: "Project",
        selector: (row) => row.project.projectName,
        cell: (row) => <span>{row.project.projectName}</span>,
        sortable: true,
        center: true,
      },

      {
        name: "Service Plan",
        cell: (row) =>
          this.getServicePlanName(row)?.length > 1 ? (
            <Popup
              trigger={
                <span className="service-plan">
                  {this.getServicePlanName(row)[0]} <span className="count">+{this.getServicePlanName(row).length - 1}</span>
                </span>
              }
              position="bottom left"
            >
              {this.getRemainingServicePlans(row)}
            </Popup>
          ) : (
            <span className="service-plan">
              {this.getServicePlanName(row)}
            </span>
          ),
        sortable: true,
        center: true,
      },

      {
        name: "Updated on",
        selector: (row) => row.updatedAt,
        cell: (row) => <span>{moment(row.updatedAt).format("lll")}</span>,
        sortable: true,
        center: true,
      },

      {
        name: "Action",
        cell: (row) =>
          <div className="d-flex">
            <Link to={{ pathname: `/page-templates/edit`, state: { row: row } }}>
              <Button size='mini' primary>Edit</Button>
            </Link>
            <Button size='mini' color='red' style={{ paddingTop: '8px', paddingBottom: '6px', }} onClick={() => this.openDeleteConfirmationModal(row._id, row)}><img alt="Delete" height={'15'} src={DeleteIcon} /></Button>
          </div>,

        center: true
      },

      ,
    ];

    return (
      <Segment>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}
        <div>
          <Confirm size="tiny" open={open} onCancel={this.close} onConfirm={this.deleteServicePlan} />

          <div>
            <div className="row mb-4 mt-2 ml-0">
              <div className="col">
                <h2>
                  {" "}
                  Page Templates{" "}
                  {this.state.isSuccess && (
                    <span classNmae="ml-2" style={{ fontSize: 14, color: "green", marginLeft: 10 }}>{this.state.isSuccess && this.state.successMessage}</span>
                  )}
                  {this.state.isError && (
                    <span classNmae="ml-2" style={{ fontSize: 14, color: "red", marginLeft: 10 }}>{this.state.isError && this.state.errorMessage}</span>
                  )}
                </h2>
              </div>
              <div className="col">
                <div className="d-flex w-100 justify-content-end">
                  <div>
                    <Input type="text" size='mini' icon='search' placeholder="search.... " className="search" onChange={this.search} />
                  </div>
                  <div className="ml-3">
                    <Link to={{ pathname: "/page-templates/add" }}><Button size='mini' primary>Add</Button></Link>
                  </div>
                </div>
              </div>
            </div>


            {this.state.responseReceived && <DataTable
              columns={columns}
              data={listPageTemplate}
              customStyles={customStyles}
              striped={true}
              highlightOnHover={true}
              pointerOnHover={true}
              responsive={true}
            />}
          </div>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(totalResults / dataPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

          <Modal onClose={this.closeDeleteConfirmationModal} onOpen={this.openDeleteConfirmationModal} open={this.state.openDeleteConfirmationModal}>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>Are you sure you want to delete?</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button type="text" onClick={this.closeDeleteConfirmationModal}>
                Cancel
              </Button>
              <Button primary type="text" onClick={this.deletePageTemplates}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageTemplates: state.PageTemplates,
    Configuration: state.Configuration,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, actionCreators, actionCreatorsProjectPages, actionCreatorsConfigurations, actionCreatorsIllumeetProjects), dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTemplates);
