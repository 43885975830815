import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Card from './Card';
import * as SideMenu from "./SideMenu";
import * as User from "./User";
import * as SearchStore from "./SearchStore";
import * as Configuration from "./Configuration";
import * as ServicePlan from "./ServicePlan";
import * as PageTemplates from "./PageTemplates";
import * as IllumeetProjects from "./IllumeetProjects";
import * as ServicePlanFiltered from "./ServicePlanFiltered";
import * as ProjectTemplates from "./ProjectTemplates";
import * as CampaignTemplates from "./CampaignTemplates";
import * as IllumeetCampaigns from "./IllumeetCampaigns";
import { reducer as formReducer } from 'redux-form'
import logger from 'redux-logger'

export default function configureStore(history, initialState) {
  const reducers = {
    card: Card.reducer,
    sideMenu: SideMenu.reducer,
    User: User.reducer,
    searchStore: SearchStore.reducer,
    ServicePlan: ServicePlan.reducer,
    Configuration: Configuration.reducer,
    form:formReducer,
    PageTemplates:PageTemplates.reducer,
    IllumeetProjects:IllumeetProjects.reducer,
    ServicePlanFiltered: ServicePlanFiltered.reducer,
    ProjectTemplates: ProjectTemplates.reducer,
    CampaignTemplates: CampaignTemplates.reducer,
    IllumeetCampaigns: IllumeetCampaigns.reducer,
  };

  const middleware = [
    thunk,
    logger,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
