import React, { Component } from "react";
import { Segment,Divider, Form, Message, Grid} from "semantic-ui-react";
import { Field, SubmissionError } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import { bindActionCreators } from "redux";
import modules from "../../common/modules";
import { actionCreators } from "../../../store/ServicePlan";
import { connect } from "react-redux";
import ServicePlanForm from "../../forms/service-plan-form";
import { RenderField, RenderDateField } from '../../common/renderField';
const { REACT_APP_API_URL }= process.env;

class EditProject extends Component {
  state={
    loading:false
  }
  constructor(props) {
    super(props);
  } 

  componentDidMount(){
      console.log('props', this.props.location.state)
  }

  handleSubmit = async (servicePlan) => {
   this.setState({loading:true});
   let site= parseInt(localStorage.getItem('site'));
   let data= { plan_name:_.get(servicePlan,'plan_name'), site: site };

   let allModules= Object.keys(modules);

   await Promise.all(allModules.map(module => {
      data[module]= (servicePlan[module])?servicePlan[module]:false;
   }));
   try{
     let servicePlanNew= await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL}/service-plans`,data);
     servicePlanNew= _.get(servicePlanNew,'data');
     this.props.addService_Plan(servicePlanNew);
     this.setState({loading:false});
     this.props.history.push(`/service-plans/${servicePlanNew.id}/edit`);
   }catch(err){
      this.setState({loading:false});
      throw SubmissionError({plan_name:'Issuee'});
   }
  };

//   loadModules = () => {

//     let keys= Object.keys(modules);
//     let moduleData = keys.map(k =>{
//         return <Grid.Column>
//                 <Field name={k} component={RenderField} as={Form.Checkbox} label={modules[k]}/>
//                </Grid.Column>;
//     });
//     return <Grid columns='6' style={{minHeight:'5vh'}}>{moduleData}</Grid>;
//   }

  render() {
    let site= parseInt(localStorage.getItem('site'));
    return (
      <Segment>
        <h1>Add Project</h1>
        <Divider/>
        {/* <ServicePlanForm site={site} loading={this.state.loading} onSubmit={this.handleSubmit} loadModules={this.loadModules}/> */}
      </Segment>
    );
  }
}


export default connect(
  state => {
    const { searchStore, ServicePlan } = state;
    return {
      state: { searchStore, ServicePlan }
    };
  },
  dispatch => bindActionCreators(actionCreators, dispatch)
)(EditProject);
