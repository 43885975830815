import axios from "axios"
const Illumeet_URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL

const GET_ALL_CAMPAIGN_REQUEST = "GET_ALL_CAMPAIGN_REQUEST"
const GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS"
const GET_ALL_CAMPAIGN_ERROR = "GET_ALL_CAMPAIGN_ERROR"

const initialState = {
    allCampaigns: [],
    message: null,
    error: false,
    getAllCampaignSuccess: false,
}

const getAllCampaignRequest = (data) => {
    return {
        type: GET_ALL_CAMPAIGN_REQUEST,
        data,
    }

}
const getAllCampaignSuccess = (data, message) => {
    return {
        type: GET_ALL_CAMPAIGN_SUCCESS,
        data,
        message,
    }
}

const getAllCampaignError = (message) => {
    return {
        type: GET_ALL_CAMPAIGN_ERROR,
        message,
    }
}

export const actionCreatorsIllumeetCampaigns = {
    getAllCampaigns: (data) => async (dispatch, getState) => {
        dispatch(getAllCampaignRequest())
        axios.post(`${Illumeet_URL}/api/campaigns/superadminCampaigns`, data).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(getAllCampaignSuccess(res.data.data, res.data.message))
                } else {
                    dispatch(getAllCampaignError(res.data.message))
                }
            } else {
                dispatch(getAllCampaignError(res.data.message))
            }
        })
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CAMPAIGN_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                getAllCampaignSuccess: false,
            }
            break;

        case GET_ALL_CAMPAIGN_SUCCESS:
            return {
                ...state,
                allCampaigns: action.data,
                message: action.message,
                error: false,
                getAllCampaignSuccess: true
            }
            break;
        case GET_ALL_CAMPAIGN_ERROR:
            return {
                ...state,
                allCampaign: [],
                message: action.message,
                error: true
            }
            break;

        default: return state
            break;
    }
}