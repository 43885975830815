import axios from "axios"
const URL = process.env.REACT_APP_API_URL

//action Constants
const SAVE_PROJECT_TEMPLATE_REQUEST = "SAVE_PROJECT_TEMPLATE_REQUEST"
const SAVE_PROJECT_TEMPLATE_SUCCESS = "SAVE_PROJECT_TEMPLATE_SUCCESS"
const SAVE_PROJECT_TEMPLATE_ERROR = "SAVE_PROJECT_TEMPLATE_ERROR"

const GET_ALL_PROJECTS_TEMPLATES_REQUEST = "GET_ALL_PROJECTS_TEMPLATES_REQUEST"
const GET_ALL_PROJECTS_TEMPLATES_SUCCESS = "GET_ALL_PROJECTS_TEMPLATES_SUCCESS"
const GET_ALL_PROJECTS_TEMPLATES_ERROR = "GET_ALL_PROJECTS_TEMPLATES_ERROR"

const DELETE_PROJECT_TEMPLATE_REQUEST = "DELETE_PROJECT_TEMPLATE_REQUEST"
const DELETE_PROJECT_TEMPLATE_SUCCESS = "DELETE_PROJECT_TEMPLATE_SUCCESS"
const DELETE_PROJECT_TEMPLATE_ERROR = "DELETE_PROJECT_TEMPLATE_ERROR"

const UPDATE_PROJECT_TEMPLATE_REQUEST = "UPDATE_PROJECT_TEMPLATE_REQUEST"
const UPDATE_PROJECT_TEMPLATE_SUCCESS = "UPDATE_PROJECT_TEMPLATE_SUCCESS"
const UPDATE_PROJECT_TEMPLATE_ERROR = "UPDATE_PROJECT_TEMPLATE_ERROR"

// Initial State
const initialState = {
    message: null,
    error: false,
    saveProjectTemplateSuccess: false,
    getAllProjectsTemplatesSuccess: false,
    projectTemplateList: [],
    totalResults: 0,
    deleteProjectTemplateSuccess: false,
    updateProjectTemplateSuccess: false,
}

// Action Creators
const saveProjectTemplateRequest = (data) => {
    return {
        type: SAVE_PROJECT_TEMPLATE_REQUEST,
        data: data
    }
}

const saveProjectTemplateSuccess = (message) => {
    return {
        type: SAVE_PROJECT_TEMPLATE_SUCCESS,
        message,
    }
}

const saveProjectTemplateError = (message) => {
    return {
        type: SAVE_PROJECT_TEMPLATE_ERROR,
        message
    }
}

const getAllProjectsTemplatesRequest = (data) => {
    return {
        type: GET_ALL_PROJECTS_TEMPLATES_REQUEST,
        data: data
    }
}

const getAllProjectsTemplatesSuccess = (message, projectTemplateList, totalResults) => {
    return {
        type: GET_ALL_PROJECTS_TEMPLATES_SUCCESS,
        message,
        projectTemplateList,
        totalResults
    }
}

const getAllProjectsTemplatesError = (message) => {
    return {
        type: GET_ALL_PROJECTS_TEMPLATES_ERROR,
        message
    }
}

const deleteProjectTemplateRequest = (data) => {
    return {
        type: DELETE_PROJECT_TEMPLATE_REQUEST,
        data: data
    }
}

const deleteProjectTemplateSuccess = (message) => {
    return {
        type: DELETE_PROJECT_TEMPLATE_SUCCESS,
        message,
    }
}

const deleteProjectTemplateError = (message) => {
    return {
        type: DELETE_PROJECT_TEMPLATE_ERROR,
        message
    }
}

const updateProjectTemplateRequest = (data) => {
    return {
        type: UPDATE_PROJECT_TEMPLATE_REQUEST,
        data: data
    }
}

const updateProjectTemplateSuccess = (message) => {
    return {
        type: UPDATE_PROJECT_TEMPLATE_SUCCESS,
        message,
    }
}

const updateProjectTemplateError = (message) => {
    return {
        type: UPDATE_PROJECT_TEMPLATE_ERROR,
        message
    }
}

// Thunk

export const actionCreatorsProjectTemplates = {
    saveProjectTemplate: (data) => async (dispatch) => {
        dispatch(saveProjectTemplateRequest(data))
        await axios.post(`${URL}/add/projectTemplates`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(saveProjectTemplateSuccess(res.data.message))
                    } else {
                        dispatch(saveProjectTemplateError(res.data.message))
                    }
                } else {
                    dispatch(saveProjectTemplateError(res.data.message))
                }
            }).catch(err => dispatch(saveProjectTemplateError(err.message)))
    },

    getAllProjectsTemplates: (data) => async (dispatch) => {
        dispatch(getAllProjectsTemplatesRequest(data))
        await axios.post(`${URL}/projectTemplates/listProjectTemplates/${data.page_no}`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(getAllProjectsTemplatesSuccess(res.data.message, res.data.projectTemplateList, res.data.totalResults))
                    } else {
                        dispatch(getAllProjectsTemplatesError(res.data.message))
                    }
                } else {
                    dispatch(getAllProjectsTemplatesError(res.data.message))
                }
            }).catch(err => dispatch(getAllProjectsTemplatesError(err.message)))
    },

    deleteProjectTemplate: (data) => async (dispatch) => {
        dispatch(deleteProjectTemplateRequest(data))
        await axios.patch(`${URL}/projectTemplates/deleteProjectTemplate/${data.id}`, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(deleteProjectTemplateSuccess(res.data.message))
                    } else {
                        dispatch(deleteProjectTemplateError(res.data.message))
                    }
                } else {
                    dispatch(deleteProjectTemplateError(res.data.message))
                }
            }).catch(err => dispatch(deleteProjectTemplateError(err.message)))
    },

    updateProjectTemplate: (data) => async (dispatch) => {
        dispatch(updateProjectTemplateRequest(data))
        await axios.patch(`${URL}/update/projectTemplates/${data.id}`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status === 200) {
                    if (res.data.status === 200) {
                        dispatch(updateProjectTemplateSuccess(res.data.message))
                    } else {
                        dispatch(updateProjectTemplateError(res.data.message))
                    }
                } else {
                    dispatch(updateProjectTemplateError(res.data.message))
                }
            }).catch(err => dispatch(updateProjectTemplateError(err.message)))
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PROJECT_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                saveProjectTemplateSuccess: false,
            }
            break;
        case SAVE_PROJECT_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                saveProjectTemplateSuccess: true,
            }
            break;

        case SAVE_PROJECT_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                saveProjectTemplateSuccess: false,
            }
            break;


        case GET_ALL_PROJECTS_TEMPLATES_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                getAllProjectsTemplatesSuccess: false,
            }
            break;
        case GET_ALL_PROJECTS_TEMPLATES_SUCCESS:
            return {
                ...state,
                message: action.message,
                projectTemplateList: action.projectTemplateList,
                totalResults: action.totalResults,
                error: false,
                getAllProjectsTemplatesSuccess: true,
            }
            break;

        case GET_ALL_PROJECTS_TEMPLATES_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                getAllProjectsTemplatesSuccess: false,
            }
            break;

        case DELETE_PROJECT_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                deleteProjectTemplateSuccess: false,
            }
            break;
        case DELETE_PROJECT_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                deleteProjectTemplateSuccess: true,
            }
            break;

        case DELETE_PROJECT_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                deleteProjectTemplateSuccess: false,
            }
            break;

        case UPDATE_PROJECT_TEMPLATE_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                updateProjectTemplateSuccess: false,
            }
            break;
        case UPDATE_PROJECT_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                updateProjectTemplateSuccess: true,
            }
            break;

        case UPDATE_PROJECT_TEMPLATE_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                updateProjectTemplateSuccess: false,
            }
            break;

        default: return state
            break;
    }

}
