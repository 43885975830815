import React, {
  Component
} from "react";
import {
  connect
} from 'react-redux';
import {
  Segment,
  Table,
  Grid
} from "semantic-ui-react";
import axios from 'axios';

import 'semantic-ui-css/semantic.min.css'

class Home extends Component {
  state = {
    data: [],
  }
  constructor(props) {
    super(props);
  }

  dashboardData = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`);
      this.setState({
        data: data.data
      });
    } catch (err) {

    }
  }
  componentDidMount = async () => {
    await this.dashboardData();
  }
  render() {
    const {
      data
    } = this.state;
    return (<
      div >
      <
      Segment style={
          {
            paddingBottom: 30
          }
        } >
       
        <div className="row mb-4 mt-2">
          <div className="col">
            <h2>
              Dashboard
            </h2>
          </div>
        </div>

        <Table celled >
          <Table.Header >
            <Table.Row >
              <Table.HeaderCell textAlign='center'> Website </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Total Users </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Total Pricing Plans </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Total Service Plans </Table.HeaderCell>
              <Table.HeaderCell textAlign='center'> Total Subscriptions </Table.HeaderCell>
            </Table.Row>
          </Table.Header>


          <Table.Body>
            <Table.Row >
              <Table.Cell textAlign='center'> Illusnap </Table.Cell>
              <Table.Cell textAlign='center' > {
                data.snap_users
              } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                    data.snap_pricing_plans
                  } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                      data.snap_service_plans
                    } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                        data.snap_subscriptions
                      } < /Table.Cell> <
      /Table.Row> <
      Table.Row >
                        <
      Table.Cell textAlign='center' > Illutag < /Table.Cell> <
      Table.Cell textAlign='center' > {
                              data.tag_users
                            } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                data.tag_pricing_plans
                              } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                  data.tag_service_plans
                                } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                    data.tag_subscriptions
                                  } < /Table.Cell> <
      /Table.Row> <
      Table.Row >
                                    <
      Table.Cell textAlign='center' > Illuflip < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                          data.flip_users
                                        } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                            data.flip_pricing_plans
                                          } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                              data.flip_service_plans
                                            } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                                data.flip_subscriptions
                                              } < /Table.Cell> <
      /Table.Row>

                                              <
      Table.Row >
                                                <
      Table.Cell textAlign='center' > IllumeetXP < /Table.Cell>  <
      Table.Cell textAlign='center' > {
                                                      data.illumeet_users
                                                    } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                                        data.illumeet_pricing_plans
                                                      } < /Table.Cell> <
      Table.Cell textAlign='center' > {
                                                          data.illumeet_service_plans
                                                        } < /Table.Cell>  <
      Table.Cell textAlign='center' > {
                                                            data.illumeet_subscriptions
                                                          } < /Table.Cell> <
      /Table.Row >

                                                          <
      /Table.Body>

                                                          <
      Table.Footer >
                                                            <
      Table.Row >
                                                              <
      Table.HeaderCell colSpan='6' >
                                                                <
      /Table.HeaderCell> <
      /Table.Row> <
      /Table.Footer> <
      /Table> <
      /Segment>

                                                                <
      /div>
                                                                );
  }
}

                                                                export default connect()(Home);