import axios from "axios"
const Illumeet_URL = process.env.REACT_APP_API_URL
const REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL

const GET_SERVICE_PLAN_FILTERED_REQUEST = "GET_SERVICE_PLAN_FILTERED_REQUEST"
const GET_SERVICE_PLAN_FILTERED_SUCCESS = "GET_SERVICE_PLAN_FILTERED_SUCCESS"
const GET_SERVICE_PLAN_FILTERED_ERROR = "GET_SERVICE_PLAN_FILTERED_ERROR"

const initialState = {
    allServicePlan: [],
    message: null,
    error: false,
    getServiceSuccess: false,
}

const getServicePlanRequest = (data) => {
    return {
        type: GET_SERVICE_PLAN_FILTERED_REQUEST,
        data,
    }

}
const getServicePlanSuccess = (data, message) => {
    return {
        type: GET_SERVICE_PLAN_FILTERED_SUCCESS,
        data,
        message,
    }
}

const getServicePlanError = (message) => {
    return {
        type: GET_SERVICE_PLAN_FILTERED_ERROR,
        message,
    }
}

export const actionCreatorsServicePlan = {
    getServicePlan: (data) => async (dispatch, getState) => {
        dispatch(getServicePlanRequest())
        
        axios.post(`${REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL}/service-plans-illumeet`, data).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(getServicePlanSuccess(res.data.data, res.data.message))
                } else {
                    dispatch(getServicePlanError(res.data.message))
                }
            } else {
                dispatch(getServicePlanError(res.data.message))
            }
        })
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_PLAN_FILTERED_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                getServiceSuccess: false,
            }
            break;

        case GET_SERVICE_PLAN_FILTERED_SUCCESS:
            return {
                ...state,
                allServicePlan: action.data,
                message: action.message,
                error: false,
                getServiceSuccess: true
            }
            break;
        case GET_SERVICE_PLAN_FILTERED_ERROR:
            return {
                ...state,
                allPages: [],
                message: action.message,
                error: true
            }
            break;
        default: return state
            break;
    }
}