import React, { Component, createRef } from "react";
import { Button, Divider, Input, Form, Dropdown, Segment, Loader, Dimmer, Image } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreatorsProjectPages } from "../../store/PageTemplates";
import { connect } from "react-redux";
import { actionCreatorsIllumeetProjects } from "../../store/IllumeetProjects";
import { actionCreatorsServicePlan } from "../../store/ServicePlanFiltered";
import { actionCreatorsConfigurations } from "../../store/Configuration";
import { actionCreatorsProjectTemplates } from "../../store/ProjectTemplates";
import { actionCreatorsIllumeetCampaigns } from "../../store/IllumeetCampaigns";
import { actionCreatorsCampaignTemplates } from "../../store/CampaignTemplates";
import UploadTemplate from "../assets/icons/upload-template.svg"
import Upload from "../assets/icons/upload.svg"
import Remove from "../assets/icons/remove.svg"
const defaultThumbnail = "/images/thumbnails/default-thumbnail.png"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

class AddCampaignTemplatesForm extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.thumbnailRef = createRef(null)
        this.pageImageExist = false
        this.state = {
            projectInfo: null,
            servicePlanIds: [],
            campaignInfo: null,
            isEditForm: false,
            errors: {},
            listOfAllProjects: [],
            listOfAllCampaigns: [],
            thumbnail: '',
            thumbnailRemoved: false,
        };
    }

    componentDidMount() {
        this.props.getSystemAccount()
        this.setState({
            loading: true
        })
        if (this.props.row && this.props.row !== undefined && this.props.row !== "") {
            let servicePlans = this.props.row.servicePlans.map(obj => (obj?._id))
            let servicePlanNames = this.props.row.servicePlans.map(obj => (obj?.plan_name))
            let projectInfo = {
                projectId: this.props.row.projectId,
                projectName: this.props.row.projectName,
            }
            let campaignInfo = {
                campaignId: this.props.row.campaignId,
                campaignName: this.props.row.campaignName,
            }
            this.setState({
                isEditForm: true,
                servicePlanIds: servicePlans,
                projectInfo,
                campaignInfo,
                templateId: this.props.row._id,
                servicePlanNames
            })
        }
        this.props.getServicePlan({ site: 4 })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.Configuration?.getSystemAccountSuccess && !prevProps.Configuration?.getSystemAccountSuccess) {
            this.setState({
                systemAccountId: this.props.Configuration?.systemAccountId
            })
            if (this.state.isEditForm) {
                this.props.getAllCampaigns({ eventId: this.props.row.projectId, systemAccountId: this.props.Configuration?.systemAccountId })
            }
            this.props.getAllProjects({ userId: this.props.Configuration.systemAccountId })
            this.state.loading && this.handleLoading()
        }

        if (this.props.IllumeetProjects?.getAllProjectSuccess && !prevProps.IllumeetProjects?.getAllProjectSuccess) {
            let listOfAllProjects = this.props.IllumeetProjects?.allProjects
            listOfAllProjects = listOfAllProjects.map(obj => ({ text: obj.eventName, value: { projectId: obj._id, projectName: obj.eventName }, key: obj._id }))
            this.setState({
                listOfAllProjects
            })
            this.state.loading && this.handleLoading()
        }

        if (this.props.IllumeetCampaigns?.getAllCampaignSuccess && !prevProps.IllumeetCampaigns?.getAllCampaignSuccess) {
            let allCampaigns = this.props.IllumeetCampaigns?.allCampaigns
            this.setState({
                listOfAllCampaignsFromIllumeetXP: allCampaigns
            }, () => {
                allCampaigns = allCampaigns.map(obj => ({ text: obj.campaignName, value: { campaignId: obj._id, campaignName: obj.campaignName, pageThumb: obj.pageThumb !== undefined && obj.pageThumb !== "" && obj.pageThumb !== null ? obj.pageThumb : "" }, key: obj._id }))
                this.setState({
                    listOfAllCampaigns: allCampaigns
                }, () => {
                    let campId = this.state.campaignInfo?.campaignId
                    let allCampaignsComplete = this.state.listOfAllCampaignsFromIllumeetXP
                    let thumbTemp = allCampaignsComplete?.length > 0 && allCampaignsComplete?.filter(el => el._id === campId)
                    let thumbImgTemp = thumbTemp[0]?.pageThumb
                    this.setState({
                        thumbnail: thumbImgTemp !== undefined ? thumbImgTemp : ''
                    })
                })
            })


            this.state.loading && this.handleLoading()
        }

        if (this.props.ServicePlanFiltered?.getServiceSuccess && !prevProps.ServicePlanFiltered?.getServiceSuccess) {
            let listOfAllServicePlan = this.props.ServicePlanFiltered?.allServicePlan
            listOfAllServicePlan = listOfAllServicePlan.map(obj => ({ text: obj.plan_name, value: obj._id, key: obj._id }))
            this.setState({
                listOfAllServicePlan,
            })
            this.state.loading && this.handleLoading()
        }

        if (this.props.CampaignTemplates?.updateCampaignTemplateSuccess && !prevProps.CampaignTemplates?.updateCampaignTemplateSuccess) {
            this.props.goBack(this.props.CampaignTemplates?.message, true)
            this.state.loading && this.handleLoading()
        }
        if (this.props.CampaignTemplates?.saveCampaignTemplateSuccess && !prevProps.CampaignTemplates?.saveCampaignTemplateSuccess) {
            this.props.goBack(this.props.CampaignTemplates?.message, true)
            this.state.loading && this.handleLoading()
        }

        if (this.props.CampaignTemplates?.error && !prevProps.CampaignTemplates?.error) {
            this.state.loading && this.handleLoading()
            this.props.isError(true, this.props.CampaignTemplates?.message)
        }
        if (this.props.ServicePlanFiltered?.error && !prevProps.ServicePlanFiltered?.error) {
            this.state.loading && this.handleLoading()
            this.props.isError(true, this.props.ServicePlanFiltered?.message)
        }
        if (this.props.IllumeetProjects?.error && !prevProps.IllumeetProjects?.error) {
            this.state.loading && this.handleLoading()
            this.props.isError(true, this.props.IllumeetProjects?.message)
        }
        if (this.props.Configuration?.error && !prevProps.Configuration?.error) {
            this.state.loading && this.handleLoading()
            this.props.isError(true, this.props.Configuration?.message)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({
            thumbnail: ''
        })
    }

    handleFieldChange = (e, data) => {
        this.setState({
            [e.target.name]: data.value
        })
    }

    handleservicePlanId = (e, data) => {
        this.setState({
            servicePlanIds: data.value,
            servicePlanNames: this.getSelectedServicePlanNames(data.value)
        })
    }

    handleprojectId = (e, data) => {
        this.setState({
            projectInfo: data.value,
        }, () => {
            this.props.getAllCampaigns({ eventId: this.state.projectInfo.projectId, systemAccountId: this.state.systemAccountId })
            this.setState({
                loading: true
            })
        })
    }

    getSelectedServicePlanNames = (data) => {
        const { listOfAllServicePlan } = this.state
        const selectedServicePlanNames = listOfAllServicePlan.filter(el => data.includes(el.value) && el.text).map(obj => obj.text)
        return selectedServicePlanNames
    }

    handleCampaignId = (e, data) => {
        this.setState({
            campaignInfo: data.value,
        })

    }

    isValid = () => {
        const { servicePlanIds, projectInfo, campaignInfo } = this.state

        let error = {}
        let isValid = true

        if (servicePlanIds === [] || servicePlanIds.length === 0 || servicePlanIds === "" || servicePlanIds === undefined || servicePlanIds === null) {
            isValid = false
            error["servicePlanIds"] = "Select at least one service plan"
        }
        if (projectInfo === {} || projectInfo === undefined || projectInfo === null) {
            isValid = false
            error["projectInfo"] = "Select a project"
        }

        if (campaignInfo === {} || campaignInfo === undefined || campaignInfo === null) {
            isValid = false
            error["campaignInfo"] = "Select a campaign"
        }

        this.setState({
            errors: error
        })
        return isValid
    }

    submitForm = (e) => {
        e.preventDefault()
        if (this.isValid()) {
            const { servicePlanIds, projectInfo, campaignInfo, systemAccountId, templateId, isEditForm, servicePlanNames, thumbnail } = this.state
            let requestBody = {
                projectId: projectInfo.projectId,
                projectName: projectInfo.projectName,
                campaignId: campaignInfo.campaignId,
                campaignName: campaignInfo.campaignName,
                servicePlanIds: servicePlanIds,
                systemAccountId,
                id: templateId ? templateId : null,
                servicePlanNames
            }
            if (isEditForm) {
                this.props.updateCampaignTemplate(requestBody)
                this.setState({
                    loading: true
                })
            } else {
                this.props.saveCampaignTemplate(requestBody)
                this.setState({
                    loading: true
                })
            }

            let formData = new FormData()
            formData.append('campThumb', thumbnail)
            formData.append('projectId', projectInfo.projectId,)
            formData.append('campaignId', campaignInfo.campaignId)
            formData.append('servicePlanIds', JSON.stringify(servicePlanIds))
            this.props.uploadCampaignTemplatesThumbnails(formData)
        }
    }

    handleLoading = () => {
        let loading = this.state.loading;
        setTimeout(() => {
            this.setState({
                loading: !loading,
            });
        }, 200);
    };

    chooseThumnailClick = () => {
        this.thumbnailRef.current.value = null
        this.thumbnailRef.current.click()
    }

    handleThumbnailChange = (e) => {
        e.preventDefault()

        if (e.target.files && e.target.files[0]) {
            const image = e.target.files[0]

            if(image.size > 5000000){
                alert('Size of image cannot be larger than 5MB.')
                return
              }
            if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
                alert('Please select valid image file.')
                return
            }
            this.setState({
                thumbnail: e.target.files[0]
            })
        }
    }

    removeThumbnail = () => {
        this.setState({
            thumbnail: '',
            thumbnailRemoved: true
        })
    }

    getThumbnailImage = () => {
        const { thumbnail, thumbnailRemoved } = this.state
        if (thumbnail !== "" && thumbnail !== undefined && thumbnail instanceof File) {
            this.pageImageExist = true
            return window.URL.createObjectURL(thumbnail)
        } else if (this.checkIfThumbExist() !== "" && this.checkIfThumbExist() !== undefined && thumbnailRemoved === false) {
            let thumbImg = this.checkIfThumbExist()
            this.pageImageExist = true
            return `${IMAGE_URL}/thumbnails/${thumbImg}`
        } else if (thumbnailRemoved && thumbnail === "") {
            this.state.thumbnailRemoved = false
            this.pageImageExist = false
            return defaultThumbnail
        } else {
            this.pageImageExist = false
            return defaultThumbnail
        }
    }

    getTempImg = () => {
        let thumbImgTemp = this.checkIfThumbExist()
        return `${IMAGE_URL}/thumbnails/${thumbImgTemp}`
    }

    checkIfThumbExist = () => {
        let campId = this.state.campaignInfo?.campaignId
        let allCampaignsComplete = this.state.listOfAllCampaignsFromIllumeetXP
        let thumbTemp = allCampaignsComplete?.length > 0 && allCampaignsComplete?.filter(el => el._id === campId)
        let thumbImgTemp = thumbTemp[0]?.pageThumb
        return thumbImgTemp
    }

    render() {
        const { listOfAllProjects, listOfAllServicePlan, listOfAllCampaigns, projectInfo, campaignInfo, servicePlanIds, errors, thumbnail } = this.state
        let options1 = listOfAllProjects
        let options2 = listOfAllServicePlan
        let options3 = listOfAllCampaigns

        let errorColor = { color: "#ff0000" }

        return (
            <div>
                {this.state.loading ? (
                    <Dimmer active inline="centered" inverted>
                        <Loader />
                    </Dimmer>
                ) : null}
                <Form >
                    <>
                        <label>Select Project</label>
                        <Dropdown
                            search onChange={(e, data) => this.handleprojectId(e, data)}
                            style={{ width: '100%' }}
                            value={projectInfo}
                            direction='downward'
                            placeholder='Select Project'
                            fluid selection
                            options={options1}
                        />
                        <p className="required small-subtitle" style={errorColor}> {errors["projectInfo"]}</p>

                        <label>Select Campaign</label>
                        <Dropdown
                            search onChange={(e, data) => this.handleCampaignId(e, data)}
                            style={{ width: '100%' }}
                            value={campaignInfo}
                            direction='downward'
                            placeholder='Select Campaign'
                            fluid selection
                            options={options3}
                        />
                        <p className="required small-subtitle" style={errorColor}> {errors["campaignInfo"]}</p>

                        {/* Thumbnail */}
                        <>
                            <div className="thumbnails">
                                <label>Project Campaign Thumbnails</label>
                                <p className="instruction-text">Choose png, jpg or jpeg files only. Maximum size 5MB.</p>

                                <div className="row">
                                    <div className="col template-thum">
                                        <div className="" >
                                            <div className="thum-preview">
                                                <img alt={'thumbnail'} src={this.getThumbnailImage()} />
                                                <div className={`btn-overlay ${!this.pageImageExist && `no-preview`}`}>
                                                    {!this.pageImageExist && <Image width="78" src={UploadTemplate} />}
                                                    <div className="mt-4 d-flex">
                                                        {!this.pageImageExist && <Button positive onClick={this.chooseThumnailClick}> <Image className="mr-2" width="14" src={Upload} />Browse Image</Button>}
                                                        {this.pageImageExist && <Button negative onClick={this.removeThumbnail}><Image className="mr-2" width="12" src={Remove} /> Remove</Button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <input
                                type="file"
                                onChange={this.handleThumbnailChange}
                                accept="image/*"
                                ref={this.thumbnailRef}
                                style={{ display: "none" }}
                            />
                        </>

                        <label>Select the Service Plan</label>
                        <Dropdown
                            search onChange={(e, data) => this.handleservicePlanId(e, data)}
                            style={{ width: '100%' }}
                            value={servicePlanIds}
                            direction='upward'
                            placeholder='Select the Service Plan'
                            multiple selection
                            options={options2}
                        />
                        <p className="required small-subtitle" style={errorColor}> {errors["servicePlanIds"]}</p>
                    </>
                    <Divider />

                    <Button primary type="submit"
                        onClick={this.submitForm}
                    >
                        Submit
                    </Button>

                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        IllumeetProjects: state.IllumeetProjects,
        ServicePlanFiltered: state.ServicePlanFiltered,
        Configuration: state.Configuration,
        ProjectTemplates: state.ProjectTemplates,
        CampaignTemplates: state.CampaignTemplates,
        IllumeetCampaigns: state.IllumeetCampaigns,
    };
};
const mapActionsToProps = (dispatch) => {
    return bindActionCreators(Object.assign({}, actionCreatorsProjectPages, actionCreatorsIllumeetProjects, actionCreatorsServicePlan, actionCreatorsConfigurations, actionCreatorsProjectTemplates, actionCreatorsIllumeetCampaigns, actionCreatorsCampaignTemplates), dispatch);
};
export default connect(mapStateToProps, mapActionsToProps)(AddCampaignTemplatesForm);
