import React, { Component } from "react";
import { Button, Header, Image, Modal, Input, Grid } from 'semantic-ui-react'
const { REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL } = process.env;
import axios from 'axios';
import _ from 'lodash';


class addImage extends Component {
  state = {
    selectedFiles: undefined,
    previewImages: [],
    progressInfos: [],
    message: [],
    imageInfos: [],
    open: false,
    error: false
  };

  constructor(props) {
    super(props);
  }

  checkExtensions(file){
    const { type }= this.props;
    var ext = file.name.split('.').pop().toLowerCase();
    let error=false;

    switch(type){
       case 'frame':
         error= (!_.includes(['png'],ext))?'Please upload a png file':false;
       break;
       case 'sticker':
         error= (!_.includes(['png','jpeg','jpg'],ext))?'Please upload jpeg, jpg or png file':false;
       break;
       case 'background':
         error= (!_.includes(['png','jpeg','jpg'],ext))?'Please upload jpeg, jpg or png file':false;
       break;
       default:
       break;
    }

    return error;
  }

  getPreSignedUrl = async() => {

      const result = await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/api/pre-signed-url`,{
              "user_id": "abc"});

      if(result.data.status === 200 ){
        return result.data;
      }else {
        return "Something went wrong";
      }
  };

  s3upload = async (method, url, file) => {
     const options = {
      method,
      // mode: 'no-cors', // no-cors, *cors, same-origin
      headers: {
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: file,
    };

    return fetch(url, options)
      .then()
      .then()
      .catch(e => {
        throw new Error(`Request Failed with => ${e.message} `);
      });
  }


  selectFiles(event) {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]))
    }

    this.setState({
      progressInfos: [],
      message: [],
      selectedFiles: event.target.files,
      previewImages: images,
      error: false
    });
  }

  uploadImages() {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
        loading: true
      },
      async () => {
        //let formData = new FormData();

        let error=false;
        
        // for(let file of selectedFiles){
        //   error= this.checkExtensions(file);

        //   if(error){
        //     break;
        //   }
        //   formData.append("image", file);

          

        // }

        let file= selectedFiles[0];

        error= this.checkExtensions(file);

        if(error){
          this.setState({error: error, loading: false});
          return;
        }

        var data_url = await this.getPreSignedUrl();

        console.log("Data Url == ",data_url);
        
        if(data_url && data_url.status === 200 ){

            let data_u = await this.s3upload('PUT',data_url.url,file);

            // let formData= new FormData();

            // formData.append("plan", this.props.plan);

            let data = { file_name: data_url.file_name, plan: this.props.plan};
            
            let response = await axios.post(`${REACT_APP_CLIENT_PANEL_ILLUSNAP_API_URL}/admin/upload-images-super/${this.props.type}`,data);

            this.setState({loading: false, open: false});

            this.props.refresh();


        }

        }
    );
  }

  setOpen= (open) => {
    this.setState({open: open})
  }

  
  
  render(){
    const { selectedFiles, previewImages, progressInfos, message, imageInfos, loading ,open, error } = this.state;
    const { buttonText }= this.props;
    return (
      <Modal
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
        open={open}
        trigger={<Button primary>{ buttonText }</Button>}
      >
        <Modal.Header>
          Select Image
          <input style={{fontSize:14, marginTop:10}} type="file" accept="image/*" onChange={this.selectFiles.bind(this)} />
       </Modal.Header>
        <Modal.Content image>
          <Modal.Description>

            {previewImages && (
              <div style={{width:'150px'}}>
                {previewImages.map((img, i) => {
                  return <img width='80px' className="preview" src={img} alt={"image-" + i}  key={i}/>;
                })}
              </div>
            )}

            {message.length > 0 && (
              <div className="alert alert-secondary" role="alert">
                <ul>
                  {message.map((item, i) => {
                    return <li key={i}>{item}</li>;
                  })}
                </ul>
              </div>
            )}

            <div className="card mt-3">
              <ul className="list-group list-group-flush">
                {imageInfos &&
                  imageInfos.map((img, index) => (
                    <li className="list-group-item" key={index}>
                      <p><a href={img.url}>{img.name}</a></p>
                      <img src={img.url} alt={img.name} height="80px" />
                    </li>
                  ))}
              </ul>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          { error && <span style={{color:'red'}}>{error}</span>}
          <Button
            content="Upload"
            labelPosition='right'
            icon='checkmark'
            disabled={!selectedFiles}
            loading={loading}
            onClick={this.uploadImages.bind(this)}
            positive
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default addImage