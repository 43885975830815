import React, { Component } from "react";
import {
  Button,
  Divider,
  Input,
  Form,
  Header
} from "semantic-ui-react";
import { Field, reduxForm } from 'redux-form';
import { RenderField, RenderDateField } from '../common/renderField';
import plans from '../common/plans';


class UserSubrscriptionDetailForm extends Component {

  render(){

	  const { handleSubmit, loadModules, loading, servicePlans, onSelect, site } = this.props;

	  console.log("Site ==== ",site);

	  return (<Form onSubmit={handleSubmit}>
	           <label>Plan Name</label>
	           <Header style={{marginTop:0}} as='h5' block>{ this.props.initialValues.plan_name }</Header>
	           <label>Service Plan Name</label>
	           <Field onChange={onSelect} name="service_plan_id" as={Form.Select} fluid lightweight options={servicePlans} component={RenderField} placeholder='Select Service Plan' width={16} />
	           {site == 1 && <>
	          <label>Total Number Of Events</label>
	            <Field name="event_number" component={RenderField} placeholder="Total Number Of Events" as={Form.Input} required/>

	          <label>Total Number Of Snaps</label>
	            <Field name="snap_number" component={RenderField} placeholder="Total Number Of Snaps" as={Form.Input} required/>

	          <label>Total Number Of Bg Removals</label>
	            <Field name="bg_removal_number" component={RenderField} placeholder="Total Number Of Bg Removals" as={Form.Input} required/>

	           <label>Select Frame Plan</label>
	           <Field name="frame_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Frame Plan' width={16} />
	           
	           <label>Select Sticker Plan</label>
	           <Field name="sticker_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Sticker Plan' width={16} />
	           
	           <label>Select Background Plan</label>
	           <Field name="background_plan" as={Form.Select} fluid lightweight options={plans} component={RenderField} placeholder='Select Background Plan' width={16} />
	           </>}
	           {site == 2 && <>
	           <label>Max Minutes</label>
	           <Field name="max_mintues" component={RenderField} placeholder="Max Minutes" as={Form.Input} required/>

	           <label>Max Steps</label>
	           <Field name="max_step" component={RenderField} placeholder="Max Steps" as={Form.Input} required/>
            </>}
	           {site == 4 && <>
	           <label>Number of events</label>
	           <Field name="noOfEvents" component={RenderField} placeholder="Number of events" as={Form.Input} required/>

	           <label>Number of campaigns</label>
	           <Field name="noOfCampaigns" component={RenderField} placeholder="Number of campaigns" as={Form.Input} required/>

	           <label>Number of audience</label>
	           <Field name="noOfAudience" component={RenderField} placeholder="Number of audience" as={Form.Input} required/>
	           
            </>}
             <label>Modules</label>
	            {loadModules()}
	           <Divider/>
	           <Button primary type="submit" loading={loading}>Submit</Button>
	          </Form>)
	}
}

UserSubrscriptionDetailForm = reduxForm({
  form: 'userSubrscriptionDetailForm',
  enableReinitialize : true
})(UserSubrscriptionDetailForm)

export default UserSubrscriptionDetailForm;