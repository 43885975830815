import axios from "axios"
const Illumeet_URL = process.env.REACT_APP_CLIENT_PANEL_ILLUMEET_XP_API_URL

const GET_ALL_PROJECT_REQUEST = "GET_ALL_PROJECT_REQUEST"
const GET_ALL_PROJECT_SUCCESS = "GET_ALL_PROJECT_SUCCESS"
const GET_ALL_PROJECT_ERROR = "GET_ALL_PROJECT_ERROR"

const UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_REQUEST = "UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_REQUEST"
const UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_SUCCESS = "UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_SUCCESS"
const UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_ERROR = "UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_ERROR"

const UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST = "UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST"
const UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS = "UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS"
const UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR = "UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR"

const UPLOAD_PAGE_TEMPLATE_THUMBNAIL_REQUEST = "UPLOAD_PAGE_TEMPLATE_THUMBNAIL_REQUEST"
const UPLOAD_PAGE_TEMPLATE_THUMBNAIL_SUCCESS = "UPLOAD_PAGE_TEMPLATE_THUMBNAIL_SUCCESS"
const UPLOAD_PAGE_TEMPLATE_THUMBNAIL_ERROR = "UPLOAD_PAGE_TEMPLATE_THUMBNAIL_ERROR"

const UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST = "UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST"
const UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS = "UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS"
const UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR = "UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR"

const initialState = {
    allProjects: [],
    message: null,
    error: false,
    getAllProjectSuccess: false,
    uploadProjectTemplateThumbnailSuccess: false,
    uploadProjectCampaignTemplateThumbnailSuccess: false,
    uploadPageTemplateThumbnailSuccess: false,
    uploadCampaignTemplateThumbnailSuccess: false,
}

const getAllProjectRequest = (data) => {
    return {
        type: GET_ALL_PROJECT_REQUEST,
        data,
    }

}
const getAllProjectSuccess = (data, message) => {
    return {
        type: GET_ALL_PROJECT_SUCCESS,
        data,
        message,
    }
}

const getAllProjectError = (message) => {
    return {
        type: GET_ALL_PROJECT_ERROR,
        message,
    }
}

const uploadProjectTemplateThumbnailRequest = (data) => {
    return {
        type: UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_REQUEST,
        data: data
    }
}

const uploadProjectTemplateThumbnailSuccess = (message) => {
    return {
        type: UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_SUCCESS,
        message,
    }
}

const uploadProjectTemplateThumbnailError = (message) => {
    return {
        type: UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_ERROR,
        message
    }
}

const uploadProjectCampaignTemplateThumbnailRequest = (data) => {
    return {
        type: UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST,
        data: data
    }
}

const uploadProjectCampaignTemplateThumbnailSuccess = (message) => {
    return {
        type: UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS,
        message,
    }
}

const uploadProjectCampaignTemplateThumbnailError = (message) => {
    return {
        type: UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR,
        message
    }
}

const uploadPageTemplateThumbnailRequest = (data) => {
    return {
        type: UPLOAD_PAGE_TEMPLATE_THUMBNAIL_REQUEST,
        data: data
    }
}

const uploadPageTemplateThumbnailSuccess = (message) => {
    return {
        type: UPLOAD_PAGE_TEMPLATE_THUMBNAIL_SUCCESS,
        message,
    }
}

const uploadPageTemplateThumbnailError = (message) => {
    return {
        type: UPLOAD_PAGE_TEMPLATE_THUMBNAIL_ERROR,
        message
    }
}

const uploadCampaignTemplateThumbnailRequest = (data) => {
    return {
        type: UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST,
        data: data
    }
}

const uploadCampaignTemplateThumbnailSuccess = (message) => {
    return {
        type: UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS,
        message,
    }
}

const uploadCampaignTemplateThumbnailError = (message) => {
    return {
        type: UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR,
        message
    }
}

export const actionCreatorsIllumeetProjects = {
    getAllProjects: (data) => async (dispatch, getState) => {
        dispatch(getAllProjectRequest())

        axios.post(`${Illumeet_URL}/api/events/superadminProjects`, data).then(res => {
            if (res.status === 200) {
                if (res.data.status === 200) {
                    dispatch(getAllProjectSuccess(res.data.data, res.data.message))
                } else {
                    dispatch(getAllProjectError(res.data.message))
                }
            } else {
                dispatch(getAllProjectError(res.data.message))
            }
        })
    },

    appendProjectTemplatesDetailsInIllumeet: (data) => async (dispatch) => {
        dispatch(uploadProjectTemplateThumbnailRequest(data))
        await axios.post(`${Illumeet_URL}/api/events/appendProjectTemplatesDetailsInIllumeet`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        dispatch(uploadProjectTemplateThumbnailSuccess(res.data.message))
                    } else {
                        dispatch(uploadProjectTemplateThumbnailError(res.data.message))
                    }
                } else {
                    dispatch(uploadProjectTemplateThumbnailError(res.data.message))
                }
            }).catch(err => dispatch(uploadProjectTemplateThumbnailError(err.message)))
    },

    uploadProjectCampaignTemplatesThumbnails: (data) => async (dispatch) => {
        dispatch(uploadProjectCampaignTemplateThumbnailRequest(data))
        await axios.post(`${Illumeet_URL}/api/events/addCampaignsThumbnail`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        dispatch(uploadProjectCampaignTemplateThumbnailSuccess(res.data.message))
                    } else {
                        dispatch(uploadProjectCampaignTemplateThumbnailError(res.data.message))
                    }
                } else {
                    dispatch(uploadProjectCampaignTemplateThumbnailError(res.data.message))
                }
            }).catch(err => dispatch(uploadProjectCampaignTemplateThumbnailError(err.message)))
    },

    uploadPageTemplatesThumbnails: (data) => async (dispatch) => {
        dispatch(uploadPageTemplateThumbnailRequest(data))
        await axios.post(`${Illumeet_URL}/api/events/addSuperadminPageThumbnail`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        dispatch(uploadPageTemplateThumbnailSuccess(res.data.message))
                    } else {
                        dispatch(uploadPageTemplateThumbnailError(res.data.message))
                    }
                } else {
                    dispatch(uploadPageTemplateThumbnailError(res.data.message))
                }
            }).catch(err => dispatch(uploadPageTemplateThumbnailError(err.message)))
    },

    uploadCampaignTemplatesThumbnails: (data) => async (dispatch) => {
        dispatch(uploadCampaignTemplateThumbnailRequest(data))
        await axios.post(`${Illumeet_URL}/api/events/addSuperadminCampaignThumbnail`, data, { headers: { 'Content-Type': 'application/json' } })
            .then(res => {
                if (res.status == 200) {
                    if (res.data.status == 200) {
                        dispatch(uploadCampaignTemplateThumbnailSuccess(res.data.message))
                    } else {
                        dispatch(uploadCampaignTemplateThumbnailError(res.data.message))
                    }
                } else {
                    dispatch(uploadCampaignTemplateThumbnailError(res.data.message))
                }
            }).catch(err => dispatch(uploadCampaignTemplateThumbnailError(err.message)))
    },
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROJECT_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                getAllProjectSuccess: false,
            }
            break;

        case GET_ALL_PROJECT_SUCCESS:
            return {
                ...state,
                allProjects: action.data,
                message: action.message,
                error: false,
                getAllProjectSuccess: true
            }
            break;
        case GET_ALL_PROJECT_ERROR:
            return {
                ...state,
                allProjects: [],
                message: action.message,
                error: true
            }
            break;

        case UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                uploadProjectTemplateThumbnailSuccess: false,
            }
            break;

        case UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                uploadProjectTemplateThumbnailSuccess: true,
            }
            break;

        case UPLOAD_PROJECT_TEMPLATE_THUMBNAIL_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                uploadProjectTemplateThumbnailSuccess: false
            }
            break;

        case UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                uploadProjectCampaignTemplateThumbnailSuccess: false,
            }
            break;

        case UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                uploadProjectCampaignTemplateThumbnailSuccess: true,
            }
            break;

        case UPLOAD_PROJECT_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                uploadProjectCampaignTemplateThumbnailSuccess: false
            }
            break;

        case UPLOAD_PAGE_TEMPLATE_THUMBNAIL_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                uploadPageTemplateThumbnailSuccess: false,
            }
            break;

        case UPLOAD_PAGE_TEMPLATE_THUMBNAIL_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                uploadPageTemplateThumbnailSuccess: true,
            }
            break;

        case UPLOAD_PAGE_TEMPLATE_THUMBNAIL_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                uploadPageTemplateThumbnailSuccess: false
            }
            break;

        case UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_REQUEST:
            return {
                ...state,
                message: null,
                error: false,
                uploadCampaignTemplateThumbnailSuccess: false,
            }
            break;

        case UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: false,
                uploadCampaignTemplateThumbnailSuccess: true,
            }
            break;

        case UPLOAD_CAMPAIGN_TEMPLATE_THUMBNAIL_ERROR:
            return {
                ...state,
                message: action.message,
                error: true,
                uploadCampaignTemplateThumbnailSuccess: false
            }
            break;

        default: return state
            break;
    }
}