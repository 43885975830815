import React, { Component, createRef } from "react";
import { Button, Divider, Input, Form, Dropdown, Label, Segment, Dimmer, Loader, Image } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreatorsProjectPages } from "../../store/PageTemplates";
import { actionCreatorsIllumeetProjects } from "../../store/IllumeetProjects";
import { actionCreatorsServicePlan } from "../../store/ServicePlanFiltered";
import { actionCreatorsConfigurations } from "../../store/Configuration";
import { connect } from "react-redux";
import PageTemplates from "../pages/templates/PageTemplates";
import UploadTemplate from "../assets/icons/upload-template.svg"
import Upload from "../assets/icons/upload.svg"
import Remove from "../assets/icons/remove.svg"
import { actionCreators } from "../../store/ServicePlan";

const defaultThumbnail = "/images/thumbnails/default-thumbnail.png"
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
class AddPageTemplateForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.thumbnailRef = createRef(null)
    this.pageImageExist = false
    this.state = {
      allProjects: [],
      allPages: [],
      projectId: "",
      allServicePlan: [],
      eventIdForService: [],
      valueForProject: null,
      valueForService: "",
      valueForPages: null,
      pageId: null,
      systemAccountId: null,
      errors: {},
      loading: false,
      limit: 10,
      servicePlans: [],
      isEditForm: false,
      thumbnail: '',
      thumbnailRemoved: false,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    const { site } = this.props;
    this.props.getServicePlan({ site: site });
    this.props.getSystemAccount();

    if (this.props.row && this.props.row !== undefined && this.props.row !== "") {
      let servicePlans = this.props.row.servicePlanId.map((obj) => obj._id);
      let servicePlanNames = this.props.row?.servicePlanId.map(obj => (obj.plan_name))
      let project = {
        projectId: this.props.row.project.projectId,
        projectName: this.props.row.project.projectName,
      };
      let pages = {
        pageId: this.props.row.pages.pageId,
        pageName: this.props.row.pages.pageName,
      };
      this.setState({
        isEditForm: true,
        eventIdForService: servicePlans,
        valueForProject: project,
        valueForPages: pages,
        pageTemplateId: this.props.row._id,
        servicePlanNames,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.IllumeetProjects.getAllProjectSuccess === true &&
      prevProps.IllumeetProjects.getAllProjectSuccess === false &&
      this.props.IllumeetProjects.message !== prevProps.IllumeetProjects.message
    ) {
      let allProjects = this.props.IllumeetProjects?.allProjects;
      allProjects = allProjects.map((obj) => ({ text: obj.eventName, value: { projectId: obj._id, projectName: obj.eventName }, key: obj._id }));
      this.setState({
        allProjects,
      });
    }

    if (
      this.props.PageTemplates.getAllPagesSuccess === true &&
      prevProps.PageTemplates.getAllPagesSuccess === false &&
      this.props.PageTemplates.message !== prevProps.PageTemplates.message
    ) {
      let allPages = this.props?.PageTemplates?.allPages;
      this.setState({
        allPagesFromIllumeetXP: allPages
      }, () => {
        allPages = allPages.map((obj) => ({ text: obj.pageName, value: { pageId: obj._id, pageName: obj.pageName, pageThumb: obj.pageThumb }, key: obj._id }));
        this.setState({
          allPages,
        }, () => {
          let pageId = this.state.valueForPages?.pageId
          let allPagesC = this.state.allPagesFromIllumeetXP
          let thumbTemp = allPagesC?.length > 0 && allPagesC?.filter(el => el._id === pageId)
          let thumbImgTemp = thumbTemp[0]?.pageThumb          
          this.setState({
            thumbnail: thumbImgTemp !== undefined ? thumbImgTemp : '' 
          })
        })
      })
    }

    if (
      this.props.ServicePlanFiltered.getServiceSuccess === true &&
      prevProps.ServicePlanFiltered.getServiceSuccess === false &&
      this.props.ServicePlanFiltered.message !== prevProps.ServicePlanFiltered.message
    ) {
      let allServicePlan = this.props.ServicePlanFiltered?.allServicePlan;
      allServicePlan = allServicePlan.map((obj) => ({ text: obj.plan_name, value: obj.id, key: obj.id }));
      this.setState({
        allServicePlan,
      });
    }

    if (
      this.props.Configuration.getSystemAccountSuccess === true &&
      prevProps.Configuration.getSystemAccountSuccess === false &&
      this.props.Configuration.message !== prevProps.Configuration.message
    ) {
      this.setState(
        {
          systemAccountId: this.props?.Configuration?.systemAccountId,
        },
        () => this.props.getAllProjects({ userId: this.props.Configuration?.systemAccountId })
      );
      if (this.state.isEditForm) {
        this.props.getAllPages({ projectId: this.props.row.project.projectId });
      }
      this.handleLoading()

    }

    if (
      this.props.PageTemplates?.error === true &&
      prevProps.PageTemplates?.error === false) {

      this.props.isError(true, this.props.PageTemplates?.message);
    }

    if (
      this.props.PageTemplates.addPageTemplatesSuccess === true &&
      prevProps.PageTemplates.addPageTemplatesSuccess === false &&
      this.props.PageTemplates.message !== prevProps.PageTemplates.message
    ) {
      this.props.goBack(this.props.PageTemplates?.message, true);
    }
    if (
      this.props.PageTemplates.updatePageTemplatesSuccess === true &&
      prevProps.PageTemplates.updatePageTemplatesSuccess === false &&
      this.props.PageTemplates.message !== prevProps.PageTemplates.message
    ) {
      this.props.goBack(this.props.PageTemplates?.message, true);
    }

    setTimeout(() => {
      if (this.state.loading) {
        this.handleLoading();
      }
    }, 200);
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      thumbnail: ''
    })
  }

  isValidPageTemplateForm = () => {
    try {
      const { projectId, valueForProject, eventIdForService, valueForPages } = this.state;
      let formIsValid = true;
      let error = {};
      if (valueForPages === {} || valueForPages === undefined || valueForPages === null) {
        formIsValid = false;
        error["valueForPages"] = "Please select page";
      }
      if (valueForProject === {} || valueForProject === undefined || valueForProject === null) {
        formIsValid = false;
        error["valueForProject"] = "Please select project";
      }

      if (
        eventIdForService.length === 0 ||
        eventIdForService === "" ||
        eventIdForService === undefined ||
        eventIdForService === [] ||
        eventIdForService === null
      ) {
        formIsValid = false;
        error["eventIdForService"] = "Select Service Plan";
      }

      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) { }
  };

  handleChangeForProject = (e, data) => {
    this.setState(
      {
        valueForProject: data.value,
        errors: {},
      },
      () => this.props.getAllPages({ projectId: this.state.valueForProject.projectId })
    );
  };
  handleChangeForPages = (e, data) => {
    this.setState({
      valueForPages: data.value,
      errors: {},
    })
  }

  handleChangeForService = (e, data) =>
    this.setState({
      eventIdForService: data.value,
      servicePlanNames: this.getSelectedServicePlanNames(data.value),
      errors: {},
    });

  getSelectedServicePlanNames = (data) => {
    const { allServicePlan } = this.state
    const selectedServicePlanNames = allServicePlan.filter(el => data.includes(el.value) && el.text).map(obj => obj.text)
    return selectedServicePlanNames
  }

  handleAddpageTemplates = () => {
    try {
      if (this.isValidPageTemplateForm()) {
        const { isEditForm, systemAccountId, valueForProject, eventIdForService, pageTemplateId, projectId, valueForPages, servicePlanNames, thumbnail } = this.state;
        const body = {
          project: {
            projectName: valueForProject.projectName,
            projectId: valueForProject.projectId,
          },
          pages: {
            pageName: valueForPages.pageName,
            pageId: valueForPages.pageId,
          },
          servicePlanNames,
          servicePlanId: eventIdForService,
          systemAccountId: systemAccountId,
          pageTemplateId: pageTemplateId ? pageTemplateId : null,
        };

        if (isEditForm) {
          this.props.updatePageTemplate(body);
        } else {
          this.props.addPageTemplates(body);
        }
        this.handleLoading();

        let formData = new FormData()
        formData.append('pageThumb', thumbnail)
        formData.append('projectId', valueForProject.projectId,)
        formData.append('pageId', valueForPages.pageId)
        formData.append('servicePlanId', JSON.stringify(eventIdForService))
        this.props.uploadPageTemplatesThumbnails(formData)
      }
    } catch (error) {
      return error;
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  chooseThumnailClick = () => {
    this.thumbnailRef.current.value = null
    this.thumbnailRef.current.click()
  }

  handleThumbnailChange = (e) => {
    e.preventDefault()

    if (e.target.files && e.target.files[0]) {
      const image = e.target.files[0]
      if(image.size > 5000000){
        alert('Size of image cannot be larger than 5MB.')
        return
      }
      if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
        alert('Please select valid image file.')
        return
      }
      this.setState({
        thumbnail: e.target.files[0]
      })
    }
  }

  removeThumbnail = () => {
    this.setState({
      thumbnail: '',
      thumbnailRemoved: true
    })
  }

  getThumbnailImage = () => {
    const { thumbnail, thumbnailRemoved } = this.state
    if (thumbnail !== "" && thumbnail !== undefined && thumbnail instanceof File) {
      this.pageImageExist = true
      return window.URL.createObjectURL(thumbnail)
    } else if (this.checkIfThumbExist() !== "" && this.checkIfThumbExist() !== undefined && thumbnailRemoved === false) {
      let thumbImg = this.checkIfThumbExist()
      this.pageImageExist = true
      return `${IMAGE_URL}/thumbnails/${thumbImg}`
    } else if (thumbnailRemoved && thumbnail === "") {
      this.state.thumbnailRemoved = false
      this.pageImageExist = false
      return defaultThumbnail
    } else {
      this.pageImageExist = false
      return defaultThumbnail
    }
  }

  getTempImg = () => {
    let thumbImgTemp = this.checkIfThumbExist()
    return `${IMAGE_URL}/thumbnails/${thumbImgTemp}`
  }

  checkIfThumbExist = () => {
    let pageId = this.state.valueForPages?.pageId
    let allPages = this.state.allPagesFromIllumeetXP
    let thumbTemp = allPages?.length > 0 && allPages?.filter(el => el._id === pageId)
    let thumbImgTemp = thumbTemp[0]?.pageThumb
    return thumbImgTemp
  }

  render() {
    let errorColor = { color: "#ff0000" };
    const { errors, eventIdForService, valueForProject, valueForPages, thumbnail } = this.state;
    const { site } = this.props;
    const options = this.state.allProjects;

    const options2 = this.state.allPages;
    const options3 = this.state.allServicePlan;

    return (
      <Segment>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}
        <Form>
          {site == 4 && (
            <>
              <label>Select Project</label>
              <Dropdown
                search onChange={(e, data) => this.handleChangeForProject(e, data)}
                style={{ width: '100%' }}
                value={valueForProject}
                direction='downward'
                placeholder='Select Project'
                fluid selection
                options={options}
              />

              <p className="required small-subtitle" style={errorColor}>
                {" "}
                {errors["valueForProject"]}
              </p>

              <label>Select Page</label>
              <Dropdown
                search onChange={(e, data) => this.handleChangeForPages(e, data)}
                style={{ width: '100%' }}
                value={valueForPages}
                direction='downward'
                placeholder='Select Page'
                fluid selection
                options={options2}
              />
              <p className="required small-subtitle" style={errorColor}>
                {" "}
                {errors["valueForPages"]}
              </p>

              <label>Select Service Plans</label>
              <Dropdown
                search onChange={(e, data) => this.handleChangeForService(e, data)}
                style={{ width: '100%' }}
                direction='downward'
                placeholder='Select Service Plans'
                multiple
                selection
                options={options3}
                value={eventIdForService}
              />
              <p className="required small-subtitle" style={errorColor}>
                {" "}
                {errors["eventIdForService"]}
              </p>

              {/* Thumbnail */}
              <>
                <div className="thumbnails">
                  <label>Project Page Thumbnails</label>
                  <p className="instruction-text">Choose png, jpg or jpeg files only. Maximum size 5MB.</p>

                  <div className="row">
                    <div className="col template-thum">
                      <div className="" >
                        <div className="thum-preview">
                          <img alt={'thumbnail'} src={this.getThumbnailImage()} />
                          <div className={`btn-overlay ${!this.pageImageExist && `no-preview`}`}>
                            {!this.pageImageExist && <Image width="78" src={UploadTemplate} />}
                            <div className="mt-4 d-flex">
                              {!this.pageImageExist && <Button positive onClick={this.chooseThumnailClick}> <Image className="mr-2" width="14" src={Upload} />Browse Image</Button>}
                              {this.pageImageExist && <Button negative onClick={this.removeThumbnail}><Image className="mr-2" width="12" src={Remove} /> Remove</Button>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <input
                  type="file"
                  onChange={this.handleThumbnailChange}
                  accept="image/*"
                  ref={this.thumbnailRef}
                  style={{ display: "none" }}
                />
              </>
            </>
          )}
          <Divider />

          <Button primary type="submit" onClick={() => this.handleAddpageTemplates()}>
            Submit
          </Button>

        </Form>
      </Segment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PageTemplates: state.PageTemplates,
    IllumeetProjects: state.IllumeetProjects,
    ServicePlanFiltered: state.ServicePlanFiltered,
    Configuration: state.Configuration,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, actionCreators, actionCreatorsIllumeetProjects, actionCreatorsServicePlan, actionCreatorsConfigurations, actionCreatorsProjectPages),
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPageTemplateForm);
