import React, {Component} from 'react'
import { Menu } from 'semantic-ui-react';

class Footer extends Component {

  render() {
    return (
      <Menu style={{}} fixed="bottom">
         <label className="footer-label">https://manage.illumeet.com - ver 1.0.0-a1</label>
      </Menu>
    );
  }
}

export default Footer;
