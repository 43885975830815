import React, { Component, createRef } from "react";
import { Button, Divider, Input, Form, Dimmer, Loader, Dropdown, Segment, Grid, Image } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { actionCreatorsProjectPages } from "../../store/PageTemplates";
import { connect } from "react-redux";
import { actionCreatorsIllumeetProjects } from "../../store/IllumeetProjects";
import { actionCreatorsServicePlan } from "../../store/ServicePlanFiltered";
import { actionCreatorsConfigurations } from "../../store/Configuration";
import { actionCreatorsProjectTemplates } from "../../store/ProjectTemplates";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import parse from 'html-react-parser';
import Configuration from "../assets/icons/configuration.svg"
import UploadTemplate from "../assets/icons/upload-template.svg"
import Upload from "../assets/icons/upload.svg"
import Remove from "../assets/icons/remove.svg"
import { actionCreatorsIllumeetCampaigns } from "../../store/IllumeetCampaigns";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const defaultThumbnail = "/images/thumbnails/default-thumbnail.png"
class AddProjectTemplatesForm extends Component {
  constructor(props) {
    super(props);
    this.pageImageExist = false
    this.campImageExist = false
    this.thumbnailRef = createRef()
    this.campThumbnailRef = createRef()
    this.state = {
      projectInfo: null,
      servicePlanIds: [],
      templateTitle: '',
      isEditForm: false,
      errors: {},
      listOfAllProjects: [],
      description: '',
      listOfPages: [],
      currentPageId: '',
      listOfCampaigns: [],
      currentCampId: '',
    };
  }

  modules = {
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold"],
        [
          { align: [] },
          { list: "bullet" },
        ],
        ["link"],
        ["underline", "blockquote"],
      ],
      handlers: { image: this.imageHandler }
    },
    clipboard: { matchVisual: false }
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align"
  ];

  componentDidMount() {
    this.props.getSystemAccount()
    this.setState({
      loading: true
    })
    if (this.props.row && this.props.row !== undefined && this.props.row !== "") {
      // let servicePlans = this.props.row.servicePlans.map(obj => (obj._id))
      // let servicePlanNames = this.props.row.servicePlans.map(obj => (obj.plan_name))
      let servicePlans = this.props.row.servicePlanIds.map(el => (el))
      let servicePlanNames = this.props.row.servicePlanNames.map(el => (el))
      let projectInfo = {
        // projectId: this.props.row.projectId,
        projectId: this.props.row?._id,
        projectName: this.props.row?.eventName,
      }
      this.setState({
        isEditForm: true,
        servicePlanIds: servicePlans,
        projectInfo,
        // templateTitle: this.props.row.templateTitle ? this.props.row.templateTitle : '',
        templateTitle: this.props.row?.projectTemplateTitle ? this.props.row?.projectTemplateTitle : '',
        templateId: this.props.row._id,
        servicePlanNames,
        description: this.props.row.description ? this.props.row.description : '',
      })
      this.props.getAllPages({ projectId: projectInfo.projectId })


    }
    this.props.getServicePlan({ site: this.props.site })
    this.setState({
      loading: true
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.Configuration?.getSystemAccountSuccess && !prevProps.Configuration?.getSystemAccountSuccess) {
      this.setState({
        systemAccountId: this.props.Configuration?.systemAccountId
      })
      this.props.getAllProjects({ userId: this.props.Configuration.systemAccountId })
      this.props.getAllCampaigns({ eventId: this.state.projectInfo?.projectId, systemAccountId: this.props.Configuration.systemAccountId })
      this.state.loading && this.handleLoading()
    }

    if (this.props.IllumeetProjects?.getAllProjectSuccess && !prevProps.IllumeetProjects?.getAllProjectSuccess) {
      let listOfAllProjects = this.props.IllumeetProjects?.allProjects
      listOfAllProjects = listOfAllProjects.map(obj => ({ text: obj.eventName, value: { projectId: obj._id, projectName: obj.eventName }, key: obj._id }))
      this.setState({
        listOfAllProjects
      })
      this.state.loading && this.handleLoading()
    }

    if (this.props.ServicePlanFiltered?.getServiceSuccess && !prevProps.ServicePlanFiltered?.getServiceSuccess) {
      let listOfAllServicePlan = this.props.ServicePlanFiltered?.allServicePlan
      listOfAllServicePlan = listOfAllServicePlan.map(obj => ({ text: obj.plan_name, value: obj._id, key: obj._id }))
      this.setState({
        listOfAllServicePlan,
      })
      this.state.loading && this.handleLoading()
    }

    if (this.props.ProjectTemplates?.updateProjectTemplateSuccess && !prevProps.ProjectTemplates?.updateProjectTemplateSuccess) {
      this.state.loading && this.handleLoading()
      this.props.goBack(this.props.ProjectTemplates?.message, true)
    }
    if (this.props.ProjectTemplates?.saveProjectTemplateSuccess && !prevProps.ProjectTemplates?.saveProjectTemplateSuccess) {
      this.state.loading && this.handleLoading()
      this.props.goBack(this.props.ProjectTemplates?.message, true)
    }

    if (this.props.PageTemplates?.getAllPagesSuccess && !prevProps.PageTemplates?.getAllPagesSuccess) {
      this.state.loading && this.handleLoading()
      this.setState({
        listOfPages: this.props.PageTemplates?.allPages,
      })
      this.props.PageTemplates?.allPages?.filter(el => el.pageProjectThumb === "").map(page => {
        this.setState({
          [`thumbnailImage${page._id}`]: ""
        })
      })
    }
    if (this.props.IllumeetCampaigns?.getAllCampaignSuccess && !prevProps.IllumeetCampaigns?.getAllCampaignSuccess) {
      this.state.loading && this.handleLoading()
      this.setState({
        listOfCampaigns: this.props.IllumeetCampaigns?.allCampaigns,
      })
      this.props.IllumeetCampaigns?.allCampaigns?.filter(el => el.pageThumb === "" || el.pageThumb === undefined || el.pageThumb === null).map(campaign => {
        this.setState({
          [`campThumbnailImage${campaign._id}`]: ""
        })
      })
    }

    if (this.props.ProjectTemplates?.error && !prevProps.ProjectTemplates?.error) {
      this.state.loading && this.handleLoading()
      this.props.isError(true, this.props.ProjectTemplates?.message)
    }
    if (this.props.ServicePlanFiltered?.error && !prevProps.ServicePlanFiltered?.error) {
      this.state.loading && this.handleLoading()
      this.props.isError(true, this.props.ServicePlanFiltered?.message)
    }
    if (this.props.IllumeetProjects?.error && !prevProps.IllumeetProjects?.error) {
      this.state.loading && this.handleLoading()
      this.props.isError(true, this.props.IllumeetProjects?.message)
    }
  }

  handleFieldChange = (e, data) => {
    this.setState({
      [e.target.name]: data.value
    })
  }

  handleservicePlanId = (e, data) => {
    this.setState({
      servicePlanIds: data.value,
      servicePlanNames: this.getSelectedServicePlanNames(data.value)
    })
  }

  handleprojectId = (e, data) => {
    this.setState({
      projectInfo: data.value,
    }, () => {
      this.props.getAllPages({ projectId: data.value.projectId })
      this.props.getAllCampaigns({ eventId: data.value.projectId, systemAccountId: this.state.systemAccountId })

    })
  }

  getSelectedServicePlanNames = (data) => {
    const { listOfAllServicePlan } = this.state
    const selectedServicePlanNames = listOfAllServicePlan.filter(el => data.includes(el.value) && el.text).map(obj => obj.text)
    return selectedServicePlanNames
  }

  isValid = () => {
    const { servicePlanIds, projectInfo, templateTitle, description } = this.state

    let error = {}
    let isValid = true

    if (servicePlanIds === [] || servicePlanIds.length === 0 || servicePlanIds === "" || servicePlanIds === undefined || servicePlanIds === null) {
      isValid = false
      error["servicePlanIds"] = "Select at least one service plan"
    }

    if (projectInfo === {} || projectInfo === undefined || projectInfo === null) {
      isValid = false
      error["projectInfo"] = "Select a project"
    }
    if (templateTitle === "" || templateTitle === undefined || templateTitle === null || templateTitle.trim().length === 0) {
      isValid = false
      error["templateTitle"] = "Enter the template title"
    }

    if (description === '' || description.trim().length === 0) {
      isValid = false
      error['description'] = 'Enter the description'
    }

    if (!Array.isArray(parse(`${description}`))) {
      if (
        typeof (parse(`${description}`).props.children) !== "string"
      ) {
        isValid = false
        error['description'] = 'Enter the description'
      }
    }
    this.setState({
      errors: error
    })
    return isValid
  }

  submitForm = (e) => {
    e.preventDefault()
    const { servicePlanIds, projectInfo, templateTitle, systemAccountId, templateId, isEditForm, servicePlanNames, description } = this.state
    if (this.isValid()) {
      let requestBody = {
        // servicePlanIds: servicePlanIds,
        projectId: projectInfo.projectId,
        // projectName: projectInfo.projectName,
        // projectTemplateTitle: templateTitle,
        systemAccountId,
        id: templateId ? templateId : null,
        // servicePlanNames,
        // description
      }
      if (isEditForm) {
        this.props.updateProjectTemplate(requestBody)
        this.setState({
          loading: true
        })
      } else {
        this.props.saveProjectTemplate(requestBody)
        this.setState({
          loading: true
        })
      }

      // for page thumbnails
      const formData = new FormData()

      formData.append('projectId', projectInfo.projectId)
      formData.append('projectTemplateTitle', templateTitle)
      formData.append('description', description)
      formData.append('servicePlanNames', JSON.stringify(servicePlanNames))
      formData.append('servicePlanIds', JSON.stringify(servicePlanIds))
      let [thumbnailsArr, noThumbnailsArr] = this.getAllThumbnails()
      for (let i = 0; i < thumbnailsArr.length; i++) {
        formData.append('thumbnails', thumbnailsArr[i])
      }
      formData.append('noThumbnails', JSON.stringify(noThumbnailsArr))
      this.props.appendProjectTemplatesDetailsInIllumeet(formData)

      // for campaign thumbnails
      const formDataCamp = new FormData()
      formDataCamp.append('projectId', projectInfo.projectId)
      let [campThumbnailsArr, noCampThumbnailsArr] = this.getAllCampThumbnails()
      for (let i = 0; i < campThumbnailsArr.length; i++) {
        formDataCamp.append('campThumbnails', campThumbnailsArr[i])
      }
      formDataCamp.append('noCampThumbnails', JSON.stringify(noCampThumbnailsArr))
      this.props.uploadProjectCampaignTemplatesThumbnails(formDataCamp)

    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    setTimeout(() => {
      this.setState({
        loading: !loading,
      });
    }, 200);
  };

  handleEditorChange = (content) => {
    this.setState({
      description: content,
    })
  }

  // for Page Thumbnails
  getAllThumbnails = () => {
    let thumbnailsArr = []
    let noThumbnailsArr = []
    for (let state in this.state) {
      if (state.match(/thumbnailImage/)) {
        if (this.state[state] !== "") {
          thumbnailsArr.push(this.state[state])
        } else {
          noThumbnailsArr.push(state.replace(/thumbnailImage/, ''))
        }
      }
    }
    return [thumbnailsArr, noThumbnailsArr]
  }

  getThumbnailImage = (page) => {
    if (this.state[`thumbnailImage${page._id}`] !== "" && this.state[`thumbnailImage${page._id}`] !== undefined) {
      this.pageImageExist = true
      return window.URL.createObjectURL(this.state[`thumbnailImage${page._id}`])
    } else if (this.state[`thumbnailImage${page._id}`] === "") {
      this.pageImageExist = false
      return defaultThumbnail
    } else if (page.projectPageThumb !== "" && page.projectPageThumb !== undefined) {
      this.pageImageExist = true
      return `${IMAGE_URL}/thumbnails/${page.projectPageThumb}`
    } else {
      this.pageImageExist = false
      return defaultThumbnail
    }
  }

  removeThumbnailImage = (page) => {
    this.setState({
      [`thumbnailImage${page._id}`]: ''
    })
  }

  addThumbnailImage = (page) => {
    this.thumbnailRef.current.value = null
    this.thumbnailRef.current.click()
    this.setState({
      currentPageId: page._id
    })
  }

  handleThumbnailChange = (e) => {
    e.preventDefault()
    try {
      const { currentPageId } = this.state
      if (e.target.files && e.target.files[0]) {
        let imageFile = e.target.files[0]

        if(imageFile.size > 5000000){
          alert('Size of image cannot be larger than 5MB.')
          return
        }
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
          alert('Please select valid image file.')
          return
        }
        var blob = imageFile.slice(0, imageFile.size, 'image/png');
        let newFile = new File([blob], `thumbnail-${currentPageId}.png`, { type: 'image/png' });
        this.setState({
          [`thumbnailImage${currentPageId}`]: newFile
        }, () => {
          this.setState({
            currentPageId: ''
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // for Campaign Thumbnails
  getAllCampThumbnails = () => {
    let campThumbnailsArr = []
    let noCampThumbnailsArr = []
    for (let state in this.state) {
      if (state.match(/campThumbnailImage/)) {
        if (this.state[state] !== "") {
          campThumbnailsArr.push(this.state[state])
        } else {
          noCampThumbnailsArr.push(state.replace(/campThumbnailImage/, ''))
        }
      }
    }
    return [campThumbnailsArr, noCampThumbnailsArr]
  }

  getCampThumbnailImage = (camp) => {
    if (this.state[`campThumbnailImage${camp._id}`] !== "" && this.state[`campThumbnailImage${camp._id}`] !== undefined) {
      this.campImageExist = true
      return window.URL.createObjectURL(this.state[`campThumbnailImage${camp._id}`])
    } else if (this.state[`campThumbnailImage${camp._id}`] === "") {
      this.campImageExist = false
      return defaultThumbnail
    } else if (camp.pageThumb !== "" && camp.pageThumb !== undefined) {
      this.campImageExist = true
      return `${IMAGE_URL}/thumbnails/${camp.pageThumb}`
    } else {
      this.campImageExist = false
      return defaultThumbnail
    }
  }

  removeCampThumbnailImage = (camp) => {
    this.setState({
      [`campThumbnailImage${camp._id}`]: ''
    })
  }

  addCampThumbnailImage = (camp) => {
    this.campThumbnailRef.current.value = null
    this.campThumbnailRef.current.click()
    this.setState({
      currentCampId: camp._id
    })
  }

  handleCampThumbnailChange = (e) => {
    e.preventDefault()
    try {
      const { currentCampId } = this.state
      if (e.target.files && e.target.files[0]) {
        let imageFile = e.target.files[0]
        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
          alert('Please select valid image file.')
          return
        }
        var blob = imageFile.slice(0, imageFile.size, 'image/png');
        let newFile = new File([blob], `thumbnail-${currentCampId}.png`, { type: 'image/png' });

        this.setState({
          [`campThumbnailImage${currentCampId}`]: newFile
        }, () => {
          this.setState({
            currentCampId: ''
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { listOfAllProjects, listOfAllServicePlan, projectInfo, templateTitle, servicePlanIds, errors, listOfPages, listOfCampaigns, pageImageExist } = this.state
    let options1 = listOfAllProjects
    let options2 = listOfAllServicePlan
    let errorColor = { color: "#ff0000" }
    return (
      <div>
        {this.state.loading ? (
          <Dimmer active inline="centered" inverted>
            <Loader />
          </Dimmer>
        ) : null}
        <Form >
          <>
            <label>Select Project</label>
            <Dropdown
              search onChange={(e, data) => this.handleprojectId(e, data)}
              style={{ width: '100%' }}
              value={projectInfo}
              direction='downward'
              placeholder='Select Project'
              fluid selection
              options={options1}
              disabled={this.state.isEditForm}
            />
            <p className="required small-subtitle" style={errorColor}> {errors["projectInfo"]}</p>

            <Form.Field
              label="Template Title"
              placeholder="Select Template Title"
              control={Input}
              name="templateTitle"
              value={templateTitle}
              // value={projectTemplateTitle}
              onChange={this.handleFieldChange}
            />
            <p className="required small-subtitle" style={errorColor}> {errors["templateTitle"]}</p>

            <Form.Field>
              <label>Description</label>
              <ReactQuill
                value={this.state.description}
                theme='snow'
                modules={this.modules}
                formats={this.formats}
                style={{ minHeight: '100px' }}
                onChange={this.handleEditorChange}
                placeholder='Enter the desciption'
              />
              <p className="required small-subtitle" style={errorColor}> {errors['description']}</p>
            </Form.Field>

            {/* Page Thumbnail */}
            {Array.isArray(listOfPages) && listOfPages.length > 0 &&
              <>
                <div className="thumbnails">
                  <label>Project Pages Thumbnails</label>
                  <p className="instruction-text">Choose png, jpg or jpeg files only. Maximum size 5MB.</p>

                  <div className="row">
                    {
                      Array.isArray(listOfPages) && listOfPages?.length > 0 &&
                      listOfPages.map((page, i) => {
                        return (
                          <div className="col template-thum">
                            <div key={i} className="" >
                              <div className="thum-preview">
                                <img alt={'thumbnail'} src={this.getThumbnailImage(page)} />
                                <div className={`btn-overlay ${!this.pageImageExist && `no-preview`}`}>
                                  {!this.pageImageExist && <Image width="78" src={UploadTemplate} />}
                                  <div className="mt-4 d-flex">
                                    {!this.pageImageExist && <Button positive onClick={() => this.addThumbnailImage(page)}> <Image className="mr-2" width="14" src={Upload} />Browse Image</Button>}
                                    {this.pageImageExist && <Button negative onClick={() => this.removeThumbnailImage(page)}><Image className="mr-2" width="12" src={Remove} /> Remove</Button>}
                                  </div>
                                </div>
                              </div>
                              <p className="thum-title">{page.pageName}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </>
            }
            <input
              type="file"
              onChange={this.handleThumbnailChange}
              accept="image/*"
              ref={this.thumbnailRef}
              style={{ display: "none" }}
              enctype='multipart/form-data'
            />

            {/* Campaign Thumbnail */}
            {/* {Array.isArray(listOfCampaigns) && listOfCampaigns.length > 0 &&

              <>

                <div className="thumbnails">
                  <label>Project Campaign Thumbnails</label>
                  <p className="instruction-text">Choose png, jpg or jpeg files only</p>

                  <div className="row">
                    {
                      Array.isArray(listOfCampaigns) && listOfCampaigns?.length > 0 &&
                      listOfCampaigns.map((campaign, i) => {
                        return (
                          <div className="col template-thum">
                            <div key={i} className="" >
                              <div className="thum-preview">
                                <img alt={'thumbnail'} src={this.getCampThumbnailImage(campaign)} />
                                <div className={`btn-overlay ${!this.campImageExist ? 'no-preview' : ''}`}>
                                 {!this.campImageExist && <Image width="78" src={UploadTemplate} />}
                                  <div className="mt-4 d-flex">
                                    {!this.campImageExist && <Button positive onClick={() => this.addCampThumbnailImage(campaign)}> <Image className="mr-2" width="14" src={Upload} />Browse Image</Button>}
                                    {this.campImageExist && <Button negative onClick={() => this.removeCampThumbnailImage(campaign)}><Image className="mr-2" width="12" src={Remove} /> Remove</Button>}
                                  </div>
                                </div>
                              </div>
                              <p className="thum-title">{campaign.campaignName}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </>
            } */}
            <input
              type="file"
              onChange={this.handleCampThumbnailChange}
              accept="image/*"
              ref={this.campThumbnailRef}
              style={{ display: "none" }}
              enctype='multipart/form-data'
            />

            <label>Select the Service Plan</label>
            <Dropdown
              search onChange={(e, data) => this.handleservicePlanId(e, data)}
              style={{ width: '100%' }}
              value={servicePlanIds}
              direction='upward'
              placeholder='Select the Service Plan'
              multiple selection
              options={options2}
            />
            <p className="required small-subtitle" style={errorColor}> {errors["servicePlanIds"]}</p>
          </>
          <Divider />

          <Button primary type="submit"
            onClick={this.submitForm}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    IllumeetProjects: state.IllumeetProjects,
    ServicePlanFiltered: state.ServicePlanFiltered,
    Configuration: state.Configuration,
    ProjectTemplates: state.ProjectTemplates,
    PageTemplates: state.PageTemplates,
    IllumeetCampaigns: state.IllumeetCampaigns
  };
};
const mapActionsToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, actionCreatorsProjectPages, actionCreatorsIllumeetProjects, actionCreatorsServicePlan, actionCreatorsConfigurations, actionCreatorsProjectTemplates, actionCreatorsIllumeetCampaigns), dispatch);
};
export default connect(mapStateToProps, mapActionsToProps)(AddProjectTemplatesForm);
